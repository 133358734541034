import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Input } from '@angular/core';
import { PHONE_TYPES } from '../../../../../../pss2-shared/phone';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators, ControlContainer } from '@angular/forms';

export function getPhonesFormArray() {
	return new UntypedFormArray([]);
}

export function createPhoneControls(): UntypedFormGroup {
	return new UntypedFormGroup({
		type: new UntypedFormControl(PHONE_TYPES[0]),
		number: new UntypedFormControl('', Validators.required),
	});
}

@Component({
	selector: 'app-phones',
	templateUrl: './phones.component.html',
	styleUrls: ['./phones.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhonesComponent implements OnInit {
	@Input()
	formArrayName: string = 'phones';

	@Input()
	isReadonly: boolean = false;

	@Input()
	withoutControls: boolean = false;

	phoneTypes = PHONE_TYPES;

	get phones(): UntypedFormArray {
		return this.controlContainer.control as UntypedFormArray;
	}

	dataEmitter = new EventEmitter<any>(true);

	constructor(
		private controlContainer: ControlContainer,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.dataEmitter.subscribe((data) => {
			if (!data) return;
			this.initControls(data.length);
			this.phones.patchValue(data);
		});
	}

	markForCheck() {
		this.cd.markForCheck();
	}

	private initControls(phonesCount: number) {
		for (let i = 0; i < phonesCount; i++) this.addPhone();
		this.cd.markForCheck();
	}

	addPhone() {
		this.phones.push(createPhoneControls());
	}

	removePhone(index) {
		this.phones.removeAt(index);
	}

	clear() {
		this.phones.clear();
	}

	getPhoneType(index) {
		return this.phones.at(index).get('type').value ?? '';
	}

	getPhoneNumber(index) {
		return this.phones.at(index).get('number').value ?? '';
	}
}
