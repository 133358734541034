<div class="modal">
  <div class="modal-dialog modal-xl fadeDown in" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span>Daily Executive Dashboard</span>
        </h3>
      </div>
      <div class="modal-body">

        <app-spinner *ngIf="isLoading" class="spin-wrapper" [size]="1"></app-spinner>

        <ng-container *ngIf="!isLoading">
          <section *ngIf="leftPartStats && todayStats" class="tables-container">
            <section class="table-container">
              <div class="table-header">
                <h3 class="table-name">{{LeftPartTitle[selectedDateRange]}}</h3>
                <div class="btn-group">
                  <clr-radio-wrapper class="radio btn btn-sm">
                    <input type="radio" clrRadio name="executive-dashboard-interval" [(ngModel)]="selectedDateRange"
                      [value]="dateOptions.yesterday" (change)="changeDateRange()" />
                    <label>{{LeftPartTitle[dateOptions.yesterday]}}</label>
                  </clr-radio-wrapper>
                  <clr-radio-wrapper class="radio btn btn-sm">
                    <input type="radio" clrRadio name="executive-dashboard-interval" [(ngModel)]="selectedDateRange"
                      [value]="dateOptions.current_week" (change)="changeDateRange()" />
                    <label>{{LeftPartTitle[dateOptions.current_week]}}</label>
                  </clr-radio-wrapper>
                </div>
              </div>
              <table class="stats-table">
                <tbody>
                  <tr>
                    <td>
                      <div class="stats-cell">
                        <span>Goals</span>
                        <span class="stats-value">{{leftPartStats.goal}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Scheduled</span>
                        <span>
                          <span class="stats-value" [style.color]="leftPartStats.scheduled.color">{{leftPartStats.scheduled.value}}</span>
                          <span>({{leftPartStats.scheduled.percentValue}}%)</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Dailies</span>
                        <span>
                          <span class="stats-value" [style.color]="leftPartStats.dailiesRevenue.color">{{leftPartStats.dailiesRevenue.value}}</span>
                          <span>({{leftPartStats.dailiesRevenue.percentValue}}%)</span>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="stats-cell">
                        <span>Dailies Revenue</span>
                        <span>
                          <span class="stats-value" [style.color]="leftPartStats.dailiesRevenue.color">{{leftPartStats.dailiesRevenue.value}}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Dailies Expenses</span>
                        <span>
                          <span class="stats-value">{{leftPartStats.dailiesExpenses}}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Dailies Profit</span>
                        <span>
                          <span class="stats-value" [style.color]="leftPartStats.dailiesProfit.color">{{leftPartStats.dailiesProfit.value}}</span>
                          <span>({{leftPartStats.dailiesProfit.percentValue}}%)</span>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="stats-cell">
                        <span>Incidents</span>
                        <span class="stats-value">
                          <a routerLink="executive-reports/incidents">{{leftPartStats.incidents}}</a>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Bids</span>
                        <span>
                          <span class="stats-value">{{leftPartStats.bids.count}}</span>
                          <span>({{leftPartStats.bids.proposalTotal}})</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Jobs</span>
                        <span>
                          <span class="stats-value">{{leftPartStats.jobs.count}}</span>
                          <span>({{leftPartStats.jobs.proposalTotal}})</span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>

              </table>
            </section>
            <section class="table-container">
              <div class="table-header">
                <h3 class="table-name">Today</h3>
              </div>
              <table class="stats-table">
                <tbody>
                  <tr>
                    <td>
                      <div class="stats-cell">
                        <span>Goals</span>
                        <span class="stats-value">{{todayStats.goal}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Scheduled</span>
                        <span>
                          <span class="stats-value" [style.color]="todayStats.scheduled.color">{{todayStats.scheduled.value}}</span>
                          <span>({{todayStats.scheduled.percentValue}}%)</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Dailies</span>
                        <span>
                          <span class="stats-value" [style.color]="todayStats.dailiesRevenue.color">{{todayStats.dailiesRevenue.value}}</span>
                          <span>({{todayStats.dailiesRevenue.percentValue}}%)</span>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="stats-cell">
                        <span>Dailies Revenue</span>
                        <span>
                          <span class="stats-value" [style.color]="todayStats.dailiesRevenue.color">{{todayStats.dailiesRevenue.value}}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Dailies Expenses</span>
                        <span>
                          <span class="stats-value">{{todayStats.dailiesExpenses}}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Dailies Profit</span>
                        <span>
                          <span class="stats-value" [style.color]="todayStats.dailiesProfit.color">{{todayStats.dailiesProfit.value}}</span>
                          <span>({{todayStats.dailiesProfit.percentValue}}%)</span>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="stats-cell">
                        <span>Incidents</span>
                        <span class="stats-value">
                          <a routerLink="executive-reports/incidents">{{todayStats.incidents}}</a>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Bids</span>
                        <span>
                          <span class="stats-value">{{todayStats.bids.count}}</span>
                          <span>({{todayStats.bids.proposalTotal}})</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="stats-cell">
                        <span>Jobs</span>
                        <span>
                          <span class="stats-value">{{todayStats.jobs.count}}</span>
                          <span>({{todayStats.jobs.proposalTotal}})</span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </section>
          <section class="chart-section">
            <div class="chart-wrapper">
              <ejs-chart class="chart" id="executive-daily-chart" #chart [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
                [legendSettings]="legendSettings" [tooltip]="tooltip" [zoomSettings]="zoom"
                (textRender)="textRender($event)">
                <e-series-collection>
                </e-series-collection>
              </ejs-chart>
            </div>
          </section>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" type="button" [clrLoading]="isSaveTimeRequestLoading" (click)="ok()">Ok</button>
      </div>
    </div>
  </div>
</div>