import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AddressComponent } from './components/address/address.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { AddressPipe } from './address.pipe';
import { GoBackDirective } from './go-back.directive';
import { PhonesPipe } from './phones.pipe';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { ModalService } from './modal-service.service';
import { ConfirmDlgComponent } from './components/confirm-dlg/confirm-dlg.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { HostDirective } from './host.directive';
import { QuillModule } from 'ngx-quill';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
	faCoffee,
	faPlusCircle,
	faPencilRuler,
	faClipboardList,
	faWrench,
	faCalendarAlt,
	faStopwatch,
	faUsers,
	faBuilding,
	faUserTie,
	faCogs,
	faTasks,
	faSitemap,
	faMoneyBillAlt,
	faBook,
	faUser,
	faDraftingCompass,
	faImages,
	faBoxes,
	faHardHat,
	faMapMarkedAlt,
	faCheckSquare,
	faTachometerAlt,
	faTty,
	faChess,
	faFilePdf,
	faIndustry,
	faAddressCard,
	faTractor,
	faCoins,
	faToolbox,
	faHandHoldingUsd,
	faMailBulk,
	faTruck,
	faCamera,
	faHandshake,
	faUserGraduate,
	faEnvelope,
	faPaperPlane,
	faIdCard,
	faTools,
	faSun,
	faCalendarCheck,
	faFileInvoiceDollar,
	faMoneyBillWave,
} from '@fortawesome/free-solid-svg-icons';
import { ToastrModule } from 'ngx-toastr';
import { PhonesComponent } from './components/phones/phones.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { SearchPopupComponent } from './components/search-popup/search-popup.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SearchTypedMapPopupComponent } from './components/search-typed-map-popup/search-typed-map-popup.component';
import { SaveProposalContractDlgComponent } from './components/save-proposal-contract-dlg/save-proposal-contract-dlg.component';
import { EmailFieldsComponent } from './components/email-fields/email-fields.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ProposalTextEditorComponent, fontsSizes } from './proposal-text-editor/proposal-text-editor.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { PaidInFullNpsComponent } from './components/paid-in-full-nps/paid-in-full-nps.component';
import { ConfirmUserHereDlgComponent } from './components/confirm-user-here-dlg/confirm-user-here-dlg.component';
import { JobPacketConfigDlgComponent } from './components/job-packet-config-dlg/job-packet-config-dlg.component';
import { SharedCPMIntercompanyRequestInfoComponent } from './components/shared-cpm-intercompany-request/shared-cpm-intercompany-request.component';
import { EventNotesListComponent } from './components/event-notes-list/event-notes-list.component';
import { PaveAmericaClientRequestDlgComponent } from './components/pave-america-client-request-dlg/pave-america-client-request-dlg.component';
import { PaveAmericaClientRequestReasonOfRejection } from './components/pave-america-client-request-reason-of-rejection-dlg/pave-america-client-request-reason-of-rejection-dlg.component';
import { PaveAmericaClientRequestsComponent } from './components/pave-america-client-requests-dlg/pave-america-client-requests/pave-america-client-requests.component';
import { PaveAmericaClientRequestsDlgComponent } from './components/pave-america-client-requests-dlg/pave-america-client-requests-dlg.component';

import Quill from 'quill';

import getQuillPlaceholderModule from 'quill-placeholder-module';
Quill.register('modules/placeholder', getQuillPlaceholderModule(Quill));

import BlotFormatter from 'quill-blot-formatter';
Quill.register('modules/blotFormatter', BlotFormatter);

import ImageCompress from 'quill-image-compress';
Quill.register('modules/imageCompress', ImageCompress);

Quill.register(Quill.import('attributors/style/align'), true);
Quill.register(Quill.import('attributors/style/background'), true);
Quill.register(Quill.import('attributors/style/color'), true);
Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/font'), true);
const Size = Quill.import('attributors/style/size');

Size.whitelist = fontsSizes;
Quill.register(Size, true);

import Parchment from 'parchment';
import { HlcTypeaheadDirectiveModule } from '../typeahead/typeahead.module';
import { WeatherDlgComponent } from './components/weather-dlg/weather-dlg.component';
import { OverlayPopupService } from './overlay-popup.service';
import { FilterCallbackPipe } from './filter-callback.pipe';
import { RouterModule } from '@angular/router';
import { ClrDgDateFilterComponent } from './components/clr-dg-filters/clr-dg-date-filter/clr-dg-date-filter.component';
import { ClrDgSelectFilterComponent } from './components/clr-dg-filters/clr-dg-select-filter/clr-dg-select-filter.component';
import { ClrDgRangeFilterComponent } from './components/clr-dg-filters/clr-dg-range-filter/clr-dg-range-filter.component';
import { ClrDgMultiSelectFilterComponent } from './components/clr-dg-filters/clr-dg-multiselect-filter/clr-dg-multiselect-filter.component';
import { TruncatePipe } from './truncate.pipe';
import { ClrDgBoolFilterComponent } from './components/clr-dg-filters/clr-dg-bool-filter/clr-dg-bool-filter.component';
import { ConfirmChangesDlgComponent } from './components/confirm-changes-dlg/confirm-changes-dlg.component';
import { CostBlockPopupComponent } from './components/cost-block-popup/cost-block-popup.component';
import { SelectAllOnFocusDirective } from './select-all-on-focus.directive';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { ClrDgStringFilterComponent } from './components/clr-dg-filters/clr-dg-string-filter/clr-dg-string-filter.component';
import { NgxsModule } from '@ngxs/store';
import { SharedState } from './store/shared.store';
import { EntityDocumentsComponent } from './components/entity-documents/entity-documents.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { PopupTextSettingsComponent } from '../measure-tool/components/popup-text-settings/popup-text-settings.component';
import { SetFocusDirective } from './set-focus.directive';
import { EditConfirmationSheetComponent } from './components/edit-confirmation-sheet/edit-confirmation-sheet.component';
import { QuillToPlainPipe } from './quill-to-plain.pipe';
import { CustomMissingTranslationHandler } from './utils/translateUtils';
import { GoogleMapsModule } from '@angular/google-maps';
import { PasswordStrengthMeterComponent } from './components/password-strength-meter/password-strength-meter.component';
import { TimezonePipe } from './timezone.pipe';
import { DndFileSelectorDlgComponent } from './components/dnd-file-selector-dlg/dnd-file-selector-dlg.component';
import { EditMaterialTableComponent } from './components/edit-material-table/edit-material-table.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { SignatureDialogComponent } from './components/signature-dialog/signature-dialog.component';
import { ThumbnailImagePopupComponent } from './components/thumbnail-image-popup/thumbnail-image-popup.component';
import { DndFileSelectorComponent } from './components/dnd-file-selector-dlg/dnd-file-selector/dnd-file-selector.component';
import { ProfitPopupComponent } from './components/profit-popup/profit-popup.component';
import { ManagerPopupComponent } from './components/manager-popup/manager-popup.component';
import { ImageViewerComponent } from './components/entity-documents/image-viewer/image-viewer.component';
import { ClarityDgFilterDirective } from './clarity-dg-filter.directive';
import { ClarityIcons, filterOffIcon } from '@cds/core/icon';
import { EmployeeEditNotesListComponent } from './components/employee-edit-notes-list/employee-edit-notes-list.component';
import { EmployeeEditNoteComponent } from './components/employee-edit-note/employee-edit-note.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ColumnOrderDlgComponent } from './components/column-order-dlg/column-order-dlg.component';
import { ClrIconModule } from '@clr/angular';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
import { SharedGalleriesDlgComponent } from './components/shared-galleries-dlg/shared-galleries-dlg.component';
import { EditSharedGalleriesDlgComponent } from './components/shared-galleries-dlg/edit-shared-gallery-dlg/edit-shared-gallery-dlg.component';
import { ProbabilityComponent } from './components/probability/probability.component';
import { ConfirmStatusChangesDlgComponent } from './components/confirm-status-changes-dlg/confirm-status-changes-dlg.component';
import { CommaSeparatedEmailsValidatorDirective } from './comma-separated-emails-validator.directive';
import { ClrDgFooterContentComponent } from './components/clr-dg-footer-content/clr-dg-footer-content.component';
import { ProjectManagerForemanSelectorComponent } from './components/project-manager-foreman-selector/project-manager-foreman-selector.component';
import { EventSelectorComponent } from './components/event-selector/event-selector.component';
import { EmailManagerDlgComponent } from './components/email-manager-dlg/email-manager-dlg.component';

const parchment = Quill.import('parchment') as typeof Parchment;

class IndentAttributor extends parchment.Attributor.Style {
	add(node, value) {
		if (value === 0) {
			this.remove(node);
			return true;
		} else {
			return super.add(node, `${value}em`);
		}
	}
}

const IndentStyle = new IndentAttributor('indent', 'text-indent', {
	scope: Parchment.Scope.BLOCK,
	whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em'],
});
Quill.register(IndentStyle, true);

const DragConfig = {
	zIndex: 10000,
};
@NgModule({
	declarations: [
		AddressComponent,
		GoBackDirective,
		AddressPipe,
		PhonesPipe,
		ModalContainerComponent,
		ConfirmDlgComponent,
		HostDirective,
		PhonesComponent,
		SearchPopupComponent,
		SearchInputComponent,
		SearchTypedMapPopupComponent,
		SaveProposalContractDlgComponent,
		ConfirmChangesDlgComponent,
		EmailFieldsComponent,
		ProposalTextEditorComponent,
		WeatherDlgComponent,
		FilterCallbackPipe,
		ClrDgDateFilterComponent,
		ClrDgStringFilterComponent,
		ClrDgSelectFilterComponent,
		ClrDgRangeFilterComponent,
		ClrDgMultiSelectFilterComponent,
		TruncatePipe,
		ClrDgBoolFilterComponent,
		CostBlockPopupComponent,
		SelectAllOnFocusDirective,
		EntityDocumentsComponent,
		ImageEditorComponent,
		PopupTextSettingsComponent,
		SetFocusDirective,
		EditConfirmationSheetComponent,
		QuillToPlainPipe,
		PasswordStrengthMeterComponent,
		PaidInFullNpsComponent,
		ConfirmUserHereDlgComponent,
		TimezonePipe,
		DndFileSelectorDlgComponent,
		EditMaterialTableComponent,
		JobPacketConfigDlgComponent,
		SignaturePadComponent,
		SignatureDialogComponent,
		ThumbnailImagePopupComponent,
		DndFileSelectorComponent,
		ProfitPopupComponent,
		ImageViewerComponent,
		ManagerPopupComponent,
		ClarityDgFilterDirective,
		EmployeeEditNotesListComponent,
		EmployeeEditNoteComponent,
		SpinnerComponent,
		ColumnOrderDlgComponent,
		SharedCPMIntercompanyRequestInfoComponent,
		EventNotesListComponent,
		SharedGalleriesDlgComponent,
		EditSharedGalleriesDlgComponent,
		PaveAmericaClientRequestDlgComponent,
		PaveAmericaClientRequestReasonOfRejection,
		PaveAmericaClientRequestsComponent,
		PaveAmericaClientRequestsDlgComponent,
		ProbabilityComponent,
		ConfirmStatusChangesDlgComponent,
		CommaSeparatedEmailsValidatorDirective,
		ClrDgFooterContentComponent,
		ProjectManagerForemanSelectorComponent,
		EventSelectorComponent,
		EmailManagerDlgComponent,
	],

	imports: [
		GoogleMapsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HlcTypeaheadDirectiveModule,
		ClarityModule,
		ClrIconModule,
		ImageCropperModule,
		NgSelectModule,
		NgOptionHighlightModule,
		NgxGpAutocompleteModule,
		QuillModule.forRoot({
			format: 'json',
			modules: {
				blotFormatter: {},
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],

					[{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
					[{ script: 'sub' }, { script: 'super' }],
					[{ indent: '-1' }, { indent: '+1' }],
					[{ font: [] }],
					[{ size: fontsSizes }],
					[{ color: [] }, { background: [] }],
					['clean'],
					['image'],
				],
			},
		}),
		FontAwesomeModule,
		ToastrModule.forRoot(),
		OverlayModule,
		DragDropModule,
		ScrollToModule.forRoot(),
		RouterModule,
		TranslateModule.forChild({
			missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
		}),
		NgxsModule.forFeature([SharedState]),
		ColorPickerModule,
	],
	providers: [
		CurrencyPipe,
		{
			provide: Loader,
			useValue: new Loader({
				apiKey: 'AIzaSyAWnqqNld7rlQSanGz31FBqIDtx8RgpWH0',
				libraries: ['places', 'geometry'],
			}),
		},
		{ provide: CDK_DRAG_CONFIG, useValue: DragConfig },
	],
	exports: [
		NgxGpAutocompleteModule,
		HlcTypeaheadDirectiveModule,
		ClarityModule,
		ImageCropperModule,
		NgSelectModule,
		NgOptionHighlightModule,
		FormsModule,
		ReactiveFormsModule,
		AddressComponent,
		GoBackDirective,
		AddressPipe,
		PhonesPipe,
		ConfirmDlgComponent,
		HostDirective,
		QuillModule,
		FontAwesomeModule,
		PhonesComponent,
		SearchInputComponent,
		SaveProposalContractDlgComponent,
		EmailFieldsComponent,
		NgxPermissionsModule,
		ProposalTextEditorComponent,
		WeatherDlgComponent,
		FilterCallbackPipe,
		ClrDgDateFilterComponent,
		ClrDgStringFilterComponent,
		ClrDgSelectFilterComponent,
		ClrDgRangeFilterComponent,
		ClrDgMultiSelectFilterComponent,
		TruncatePipe,
		ClrDgBoolFilterComponent,
		ConfirmChangesDlgComponent,
		SelectAllOnFocusDirective,
		TranslateModule,
		EntityDocumentsComponent,
		PopupTextSettingsComponent,
		EditConfirmationSheetComponent,
		QuillToPlainPipe,
		GoogleMapsModule,
		PasswordStrengthMeterComponent,
		PaidInFullNpsComponent,
		ConfirmUserHereDlgComponent,
		TimezonePipe,
		EditMaterialTableComponent,
		JobPacketConfigDlgComponent,
		SignaturePadComponent,
		ThumbnailImagePopupComponent,
		DndFileSelectorComponent,
		ProfitPopupComponent,
		ManagerPopupComponent,
		ClarityDgFilterDirective,
		SpinnerComponent,
		SharedCPMIntercompanyRequestInfoComponent,
		EventNotesListComponent,
		PaveAmericaClientRequestDlgComponent,
		PaveAmericaClientRequestsDlgComponent,
		ProbabilityComponent,
		ConfirmStatusChangesDlgComponent,
		ClrDgFooterContentComponent,
		ProjectManagerForemanSelectorComponent,
		EventSelectorComponent,
		EmailManagerDlgComponent
	],
})
export class SharedModule {
	constructor(config: NgSelectConfig, library: FaIconLibrary) {
		config.placeholder = 'Type to search...';
		ClarityIcons.addIcons(filterOffIcon);
		library.addIcons(
			faCoins,
			faCoffee,
			faPlusCircle,
			faPencilRuler,
			faClipboardList,
			faWrench,
			faCalendarAlt,
			faStopwatch,
			faUsers,
			faBuilding,
			faTractor,
			faAddressCard,
			faUserTie,
			faCogs,
			faTasks,
			faSitemap,
			faMoneyBillAlt,
			faBook,
			faBuilding,
			faUser,
			faDraftingCompass,
			faImages,
			faBoxes,
			faHardHat,
			faMapMarkedAlt,
			faCheckSquare,
			faTachometerAlt,
			faTty,
			faChess,
			faFilePdf,
			faIndustry,
			faToolbox,
			faHandHoldingUsd,
			faMailBulk,
			faTruck,
			faCamera,
			faHandshake,
			faUserGraduate,
			faEnvelope,
			faPaperPlane,
			faIdCard,
			faMapMarkedAlt,
			faTools,
			faSun,
			faCalendarCheck,
			faFileInvoiceDollar,
			faMoneyBillWave,
		);
	}
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [ModalService, OverlayPopupService],
		};
	}
}
