import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { format, endOfWeek, eachWeekOfInterval, eachDayOfInterval, endOfDay, endOfMonth, eachMonthOfInterval } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { tap } from 'rxjs/operators';
import { AuthState } from 'src/app/auth/store/auth.store';
import { US_DATE_FMT, parseUSDate } from 'src/app/shared/utils/dateUtils';

export enum DashboardInterval {
	month,
	week,
	day,
}

interface DashboardIntervalPoint {
	label: string;
	startDate: string;
	endDate: string;
}

@Injectable({
	providedIn: 'root',
})
export class DashboardDatesService {
	weekStartsOn: 0 | 1 = 0;

	constructor(private store: Store) {
		this.store
			.select(AuthState.companySharedSettings)
			.pipe(
				tap((settings) => {
					this.weekStartsOn = settings?.isWeekStartsOnMonday ? 1 : 0;
				})
			)
			.subscribe();
	}

	getDashboardIntervalPointsDateRange1(
		timezone: string,
		viewPoint: DashboardInterval,
		startDate: string,
		endDate: string,
		fmt?: string
	): DashboardIntervalPoint[] {
		const viewPointsDateRanges: DashboardIntervalPoint[] = [];
		switch (viewPoint) {
			case DashboardInterval.month: {
				const months = eachMonthOfInterval({
					start: parseUSDate(startDate),
					end: parseUSDate(endDate),
				});
				for (const month of months) {
					viewPointsDateRanges.push({
						label: format(month, fmt ?? US_DATE_FMT),
						startDate: zonedTimeToUtc(month, timezone).toISOString(),
						endDate: zonedTimeToUtc(endOfMonth(month), timezone).toISOString(),
					});
				}
				break;
			}
			case DashboardInterval.day: {
				const days = eachDayOfInterval({
					start: parseUSDate(startDate),
					end: parseUSDate(endDate),
				});
				for (const day of days) {
					viewPointsDateRanges.push({
						label: format(day, fmt ?? US_DATE_FMT, {
							useAdditionalDayOfYearTokens: true,
						}),
						startDate: zonedTimeToUtc(day, timezone).toISOString(),
						endDate: zonedTimeToUtc(endOfDay(day), timezone).toISOString(),
					});
				}
				break;
			}
			case DashboardInterval.week: {
				const weeks = eachWeekOfInterval(
					{
						start: parseUSDate(startDate),
						end: parseUSDate(endDate),
					},
					{ weekStartsOn: this.weekStartsOn }
				);
				for (const week of weeks) {
					viewPointsDateRanges.push({
						label: format(week, fmt ?? 'ww (MM/dd/yyyy)'),
						startDate: zonedTimeToUtc(week, timezone).toISOString(),
						endDate: zonedTimeToUtc(endOfWeek(week, { weekStartsOn: this.weekStartsOn }), timezone).toISOString(),
					});
				}
				break;
			}
		}
		return viewPointsDateRanges;
	}

	getDashboardIntervalPointsDateRange(
		timezone: string,
		viewPoint: DashboardInterval,
		startDate: string,
		endDate: string,
		fmt?: string
	): DashboardIntervalPoint[] {
		const startParsedDate = parseUSDate(startDate);
		const endParsedDate = parseUSDate(endDate);

		const viewPointsDateRanges: DashboardIntervalPoint[] = [];
		switch (viewPoint) {
			case DashboardInterval.month: {
				const months = eachMonthOfInterval({
					start: startParsedDate,
					end: endParsedDate,
				});
				for (let i = 0; i < months.length; i++) {
					const month = months[i];
					const start = i === 0 ? startParsedDate : month;
					const end = i === months.length - 1 ? endOfDay(endParsedDate) : endOfMonth(month);
					viewPointsDateRanges.push({
						label: format(start, fmt ?? US_DATE_FMT),
						startDate: zonedTimeToUtc(start, timezone).toISOString(),
						endDate: zonedTimeToUtc(end, timezone).toISOString(),
					});
				}
				break;
			}
			case DashboardInterval.day: {
				const days = eachDayOfInterval({
					start: startParsedDate,
					end: endParsedDate,
				});
				for (let i = 0; i < days.length; i++) {
					const day = days[i];
					viewPointsDateRanges.push({
						label: format(day, fmt ?? US_DATE_FMT, {
							useAdditionalDayOfYearTokens: true,
						}),
						startDate: zonedTimeToUtc(day, timezone).toISOString(),
						endDate: zonedTimeToUtc(endOfDay(day), timezone).toISOString(),
					});
				}
				break;
			}
			case DashboardInterval.week: {
				const weeks = eachWeekOfInterval(
					{
						start: startParsedDate,
						end: endParsedDate,
					},
					{ weekStartsOn: this.weekStartsOn }
				);

				const usedWeekNumbers = new Set();
				let lastWeekNumber = null;

				for (let i = 0; i < weeks.length; i++) {
					const week = weeks[i];
					const start = i === 0 ? startParsedDate : week;
					const end = i === weeks.length - 1 ? endOfDay(endParsedDate) : endOfWeek(week, { weekStartsOn: this.weekStartsOn });

					let weekNumber = parseInt(format(start, 'ww'));
					
					if (fmt === 'ww') {
						if (usedWeekNumbers.has(weekNumber)) {
							weekNumber = lastWeekNumber + 1;
						}
						usedWeekNumbers.add(weekNumber);
						lastWeekNumber = weekNumber;
					}
				
					viewPointsDateRanges.push({
						label:  fmt === 'ww' ? String(weekNumber) : format(start, fmt ?? 'ww (MM/dd/yyyy)'),
						startDate: zonedTimeToUtc(start, timezone).toISOString(),
						endDate: zonedTimeToUtc(end, timezone).toISOString(),
					});
				}
				break;
			}
		}

		return viewPointsDateRanges;
	}
}
