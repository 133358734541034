import { UntypedFormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { paveAmericaOpportunityTypes } from '@pss2-shared/paveAmerica';

/**
 * makes the field required if the predicate function returns true
 */
export function requiredIfValidator(predicate: (formControl?: AbstractControl) => boolean) {
	return (formControl: AbstractControl) => {
		if (predicate(formControl)) {
			return Validators.required(formControl);
		}
		return null;
	};
}

export function ifValidator(validator: (control: AbstractControl) => ValidationErrors | null, predicate: () => boolean) {
	return (formControl: AbstractControl) => {
		if (predicate()) {
			return validator(formControl);
		}
		return null;
	};
}

export function minLength(minLength: number): (control: AbstractControl) => ValidationErrors | null {
	return (control: AbstractControl): ValidationErrors | null => {
		return control?.value?.length >= minLength ? null : { minlength: true };
	};
}

export function specialCharacters(control: AbstractControl): ValidationErrors | null {
	const regex = /^(.*[?!@#$%^&{}~()[\]+=:;\-<>'"*])([a-zA-Z0-9?!@#$%^&{}~()[\]+=:;\-<>'"*]*)$/;
	return regex.test(control.value) ? null : { specialChar: true };
}

export function upperCharacters(control: AbstractControl): ValidationErrors | null {
	const regex = /^(?=.*[A-Z])(.*)$/;
	return regex.test(control.value) ? null : { upperChar: true };
}

export function lowerCharacters(control: AbstractControl): ValidationErrors | null {
	const regex = /^(?=.*[a-z])(.*)$/;
	return regex.test(control.value) ? null : { lowerChar: true };
}

export function nonZeroValidator(control: AbstractControl): ValidationErrors | null {
	const value = control.value;
	return value == 0 ? { 'non-zero': true } : null;
}

export function arrayMinLength(minLength: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value || !Array.isArray(control.value) || control.value.length < minLength) {
			return {
				arrayMinLength: {
					requiredLength: minLength,
					actualLength: control.value ? control.value.length : 0,
				},
			};
		}

		return null;
	};
}

export function arrayExactLength(length: number) {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value || !Array.isArray(control.value) || control.value.length !== length) {
			return {
				arrayExactLength: {
					length,
					actualLength: control.value ? control.value.length : 0,
				},
			};
		}

		return null;
	};
}

export class ClearFormControl extends UntypedFormControl {
	markAsDirty(): void {}
}

export function minSelectedItems(min: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const selectedItems = control.value.filter(Boolean);

		return selectedItems.length < min
			? {
					minSelectedItems: true,
				}
			: null;
	};
}

export function commaSeparatedEmailsValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		const emails = control.value.split(',').map((email) => email.trim());
		for (const email of emails) {
			if (Validators.email(new UntypedFormControl(email))) {
				return { commaSeparatedEmails: true };
			}
		}

		return null;
	};
}

export function paveAmericaOpportunityTypeValidator(control: AbstractControl): ValidationErrors | null {
	if (!control.value) {
		return null;
	}

	if (paveAmericaOpportunityTypes.includes(control.value)) {
		return null;
	}

	return { paveAmericaOpportunityType: true };
}

export enum UNIT_VARIANCE_OPTION {
	asphalt_tons = 'asphalt_tons',
	asphalt_area = 'asphalt_area',
	concrete_yards = 'concrete_yards',
	concrete_area = 'concrete_area',
	labor_hours = 'labor_hours',
	trucking_hours = 'trucking_hours',
	sealcoat_gallons = 'sealcoat_gallons',
	sealcoat_area = 'sealcoat_area',
	crackfill_blocks = 'crackfill_blocks',
	crackfill_linear_feet = 'crackfill_linear_feet',
	stone_tons = 'stone_tons',
	stone_area = 'stone_area',
	cement_tons = 'cement_tons',
	cement_area = 'cement_area',
	additional_costs = 'additional_costs',
}

export enum DEFAULT_UNIT_VARIANCE_OPTION {
	asphalt_tons = 'asphalt_tons',
	asphalt_area = 'asphalt_area',
	concrete_yards = 'concrete_yards',
	concrete_area = 'concrete_area',
	sealcoat_area = 'sealcoat_area',
	crackfill_linear_feet = 'crackfill_linear_feet',
	labor_hours = 'labor_hours',
	trucking_hours = 'trucking_hours',
}

export const UnitVarianceOptionLabel = {
	[UNIT_VARIANCE_OPTION.asphalt_tons]: 'Asphalt Tons',
	[UNIT_VARIANCE_OPTION.asphalt_area]: 'Asphalt Area',
	[UNIT_VARIANCE_OPTION.concrete_yards]: 'Concrete Yards',
	[UNIT_VARIANCE_OPTION.concrete_area]: 'Concrete Area',
	[UNIT_VARIANCE_OPTION.labor_hours]: 'Labor Hours',
	[UNIT_VARIANCE_OPTION.trucking_hours]: 'Trucking Hours',
	[UNIT_VARIANCE_OPTION.sealcoat_gallons]: 'Sealcoat Gallons',
	[UNIT_VARIANCE_OPTION.sealcoat_area]: 'Sealcoat Area',
	[UNIT_VARIANCE_OPTION.crackfill_blocks]: 'Crackfill Blocks',
	[UNIT_VARIANCE_OPTION.crackfill_linear_feet]: 'Crackfill Linear Feet',
	[UNIT_VARIANCE_OPTION.stone_tons]: 'Stone Tons',
	[UNIT_VARIANCE_OPTION.stone_area]: 'Stone Area',
	[UNIT_VARIANCE_OPTION.cement_tons]: 'Cement Tons',
	[UNIT_VARIANCE_OPTION.cement_area]: 'Cement Area',
	[UNIT_VARIANCE_OPTION.additional_costs]: 'Additional Costs',
} as const;

export const UNIT_VARIANCE_OPTION_MAX = 8;
export const UNIT_VARIANCE_OPTION_MIN = 4;
