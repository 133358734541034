export interface CompanyRegion {
	id: number;
	name: string;
}

export enum PaveAmericaOpportunityType {
	commercial = 'Commercial',
	government = 'Government',
	general_contractor = 'General Contractor',
	hoa = 'HOA',
	nonprofit = 'Nonprofit',
	partner_company = 'Partner Company',
	private = 'Private',
	utility = 'Utility',
	other = 'Other',
}

export const paveAmericaOpportunityTypes = Object.values(PaveAmericaOpportunityType);

export interface AddCompanyToPaveAmericaRequest {
	regionId: number;
	userId: number;
}

export enum PaveAmericaClientRequestStatus {
	declined_by_requester = 'declined_by_requester',
	in_inbound_review = 'in_inbound_review',
	declined_in_inbound_review = 'declined_in_inbound_review',
	in_outbound_review = 'in_outbound_review',
	declined_in_outbound_review = 'declined_in_outbound_review',
	approved = 'approved',
}

export const paveAmericaClientRequestStatusToLabel: Record<PaveAmericaClientRequestStatus, string> = {
	[PaveAmericaClientRequestStatus.declined_by_requester]: 'Declined By Requester',
	[PaveAmericaClientRequestStatus.in_inbound_review]: 'In Inbound Review',
	[PaveAmericaClientRequestStatus.declined_in_inbound_review]: 'Declined in Inbound Review',
	[PaveAmericaClientRequestStatus.in_outbound_review]: 'In Outbound Review',
	[PaveAmericaClientRequestStatus.declined_in_outbound_review]: 'Declined in Outbound Review',
	[PaveAmericaClientRequestStatus.approved]: 'Approved',
};

export const paveAmericaClientRequestStatusOptions = Object.values(PaveAmericaClientRequestStatus).map((status) => ({
	id: status,
	name: paveAmericaClientRequestStatusToLabel[status],
}));

export const DECLINED_PAVE_AMERICA_CLIENT_REQUEST_STATUSES = [
	PaveAmericaClientRequestStatus.declined_by_requester,
	PaveAmericaClientRequestStatus.declined_in_inbound_review,
	PaveAmericaClientRequestStatus.declined_in_outbound_review,
];

export const APPROVED_PAVE_AMERICA_CLIENT_REQUEST_STATUSES = [PaveAmericaClientRequestStatus.approved];

export const FINAL_PAVE_AMERICA_CLIENT_REQUEST_STATUSES = [...DECLINED_PAVE_AMERICA_CLIENT_REQUEST_STATUSES, ...APPROVED_PAVE_AMERICA_CLIENT_REQUEST_STATUSES];
