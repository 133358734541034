import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { Modal } from 'src/app/shared/modal-service-models/modal.model';

@Component({
	selector: 'app-pave-america-client-request-reason-of-rejection',
	templateUrl: './pave-america-client-request-reason-of-rejection.component.html',
	styleUrls: ['./pave-america-client-request-reason-of-rejection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaveAmericaClientRequestReasonOfRejection extends Modal {
	@ViewChild(ClrForm, { static: true })
	clrForm: ClrForm;

	reasonOfRejectionControl = new FormControl<string>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(500)]);

	onInjectInputs(): void {}

	ok() {
		if (!this.reasonOfRejectionControl.valid) {
			this.clrForm.markAsTouched();
			return;
		}

		this.close(this.reasonOfRejectionControl.value);
	}

	cancel() {
		this.close(null);
	}
}
