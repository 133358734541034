import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngxs/store';
import { PaveAmericaClientRequestStatus } from '@pss2-shared/paveAmerica';
import { PSS_PERMISSION_ID } from '@pss2-shared/permissions';
import { AuthState } from 'src/app/auth/store/auth.store';
import { LoadActivePaveAmericaClientRequestsCount } from 'src/app/crm/store/pave-america-client-requests.actions';
import { Modal } from 'src/app/shared/modal-service-models/modal.model';

@Component({
	selector: 'app-pave-america-client-requests-dlg',
	templateUrl: './pave-america-client-requests-dlg.component.html',
	styleUrls: ['./pave-america-client-requests-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaveAmericaClientRequestsDlgComponent extends Modal {
	clientId: number;

	selectedStatuses: PaveAmericaClientRequestStatus[] = [];

	constructor(private store: Store) {
		super();
	}

	onInjectInputs(inputs) {
		const user = this.store.selectSnapshot(AuthState.user);
		if (user.permissions.has(PSS_PERMISSION_ID.review_pave_america_client_requests)) {
			this.store.dispatch(new LoadActivePaveAmericaClientRequestsCount());
		}

		this.clientId = inputs.clientId;
		this.selectedStatuses = inputs.selectedStatuses;
	}

	cancel() {
		this.close(null);
	}
}
