import { COSTS_BLOCKS } from './costsBlocks';

export const MIME_IMAGE_PREFIX = 'image/';
export const MIME_PDF = 'application/pdf';

export enum BID_STATUSES {
	NEW = 'New',
	WON = 'Won',
	LOST = 'Lost',
	QUOTED = 'Quoted',
	WAITING = 'Pending Manager Approval',
	APPROVED = 'Manager Approved',
}

export const MASS_EMAIL_BID_STATUSES = [BID_STATUSES.NEW, BID_STATUSES.WON, BID_STATUSES.LOST, BID_STATUSES.QUOTED];

export const INCREASE_BID_ITEM_PRICE_EMAIL_BID_STATUSES = [
	BID_STATUSES.NEW,
	BID_STATUSES.WON,
	BID_STATUSES.LOST,
	BID_STATUSES.QUOTED,
	BID_STATUSES.WAITING,
	BID_STATUSES.APPROVED,
];

export enum BID_TYPE {
	FIXED_PRICE = 'fixed_price',
	UNIT_PRICING = 'unit_pricing',
	BONDED = 'bonded',
}

export const BidTypeToLabelMap: {
	[key in BID_TYPE]: string;
} = {
	[BID_TYPE.FIXED_PRICE]: 'Fixed Price',
	[BID_TYPE.UNIT_PRICING]: 'Unit Pricing',
	[BID_TYPE.BONDED]: 'Bonded',
};

export enum PROPOSAL_ATTACHMENTS_PROPOSAL_TYPE {
	ALL = 'all',
	LONG = 'long',
	SIMPLE = 'simple',
}

export const ProposalAttachmentProposalTypeToLabelMap: {
	[key in PROPOSAL_ATTACHMENTS_PROPOSAL_TYPE]: string;
} = {
	[PROPOSAL_ATTACHMENTS_PROPOSAL_TYPE.ALL]: 'All Proposals',
	[PROPOSAL_ATTACHMENTS_PROPOSAL_TYPE.LONG]: 'Long Proposals',
	[PROPOSAL_ATTACHMENTS_PROPOSAL_TYPE.SIMPLE]: 'Simple Proposals',
};

export const BID_TYPES = [
	{
		id: BID_TYPE.FIXED_PRICE,
		name: BidTypeToLabelMap[BID_TYPE.FIXED_PRICE],
	},
	{
		id: BID_TYPE.UNIT_PRICING,
		name: BidTypeToLabelMap[BID_TYPE.UNIT_PRICING],
	},
	{
		id: BID_TYPE.BONDED,
		name: BidTypeToLabelMap[BID_TYPE.BONDED],
	},
];

export enum EMPLOYEE_PAY_TYPE {
	HOURLY = 'Hourly',
	SALARY = 'Salary',
}

export function getEmployeeTypeShort(e: EMPLOYEE_PAY_TYPE) {
	return e[0];
}

export enum VERSION_SURVEY_STATUSES {
	SENT = 'Sent',
	COMPLETED = 'Completed',
}

/**
 * These statuses must be present in the DB for all companies
 */
export enum SYSTEM_JOB_STATUS_EMBED {
	IN_PROGRESS = 'In Progress', //initial value for new Jobs, used by old system
	WORK_DONE = 'Work Done', //when it's set the system creates todo assigned to a Bid's user, it is set automatically when saving scheduler events if all Job's events are "completed"
	READY_TO_BILL = 'Ready to Bill',
	BILLED = 'Billed', //set when QB Desktop or QB Online confirms that related to Job invoice is created, used by old system
	COMPLETE = 'Paid In Full', //to mark Job as completed, supposed to be eventual Job's state, used by old system
}

/**
 * These statuses must be present in the DB for PA companies
 */
export enum NS_JOB_STATUS_EMBED {
	CLOSED = 'Closed',
	PARTIALLY_BILLED = 'Partially Billed',
	PARTIALLY_PAID = 'Partially Paid',
	READY_TO_PARTIALLY_BILL = 'Ready to Partially Bill',
	HOLD = 'Hold',
	ARCHIVE_INACTIVE = 'Archive - Inactive',
	COMMISSIONS_PAID = 'Commissions Paid - Job Closed',
	READY_FOR_COMMISSIONS = 'Ready for Commissions',
	INTERCOMPANY_BILLING = 'Intercompany Billing',
	NOT_STARTED = 'Not Started',
	WARRANTY = 'Warranty',
	BILLED_WITH_RETAINAGE = 'Billed w/Retainage',
}

export type JOB_STATUS_EMBED = SYSTEM_JOB_STATUS_EMBED | NS_JOB_STATUS_EMBED;

export const SYSTEM_JOB_STATUSES_EMBED_ORDER: readonly JOB_STATUS_EMBED[] = [
	SYSTEM_JOB_STATUS_EMBED.IN_PROGRESS,
	SYSTEM_JOB_STATUS_EMBED.WORK_DONE,
	SYSTEM_JOB_STATUS_EMBED.READY_TO_BILL,
	SYSTEM_JOB_STATUS_EMBED.BILLED,
	SYSTEM_JOB_STATUS_EMBED.COMPLETE,
];

export const jobStatusEmbedToStatusInfo: Record<JOB_STATUS_EMBED, string> = {
	[SYSTEM_JOB_STATUS_EMBED.IN_PROGRESS]: 'In progress - initial value for newly won Jobs',
	[SYSTEM_JOB_STATUS_EMBED.WORK_DONE]:
		'Work Done - set automatically when all work order items in a Job are "completed". The system then notifies the Salesperson',
	[SYSTEM_JOB_STATUS_EMBED.READY_TO_BILL]: 'Ready to Bill - used to mark a job as ready for billing.',
	[SYSTEM_JOB_STATUS_EMBED.BILLED]: 'Billed - automatically set when QB Desktop or QB Online confirms that the Job invoice is created',
	[SYSTEM_JOB_STATUS_EMBED.COMPLETE]:
		'Paid in Full - used to mark a job as completed. Jobs with this status will be filtered out by default in the jobs table.',
	[NS_JOB_STATUS_EMBED.CLOSED]: 'Closed',
	[NS_JOB_STATUS_EMBED.PARTIALLY_BILLED]: 'Partially Billed',
	[NS_JOB_STATUS_EMBED.PARTIALLY_PAID]: 'Partially Paid',
	[NS_JOB_STATUS_EMBED.READY_TO_PARTIALLY_BILL]: 'Ready to Partially Bill',
	[NS_JOB_STATUS_EMBED.HOLD]: 'Hold',
	[NS_JOB_STATUS_EMBED.ARCHIVE_INACTIVE]: 'Archive - Inactive',
	[NS_JOB_STATUS_EMBED.COMMISSIONS_PAID]: 'Commissions Paid - Job Closed',
	[NS_JOB_STATUS_EMBED.READY_FOR_COMMISSIONS]: 'Ready for Commissions',
	[NS_JOB_STATUS_EMBED.INTERCOMPANY_BILLING]: 'Intercompany Billing',
	[NS_JOB_STATUS_EMBED.NOT_STARTED]: 'Not Started',
	[NS_JOB_STATUS_EMBED.WARRANTY]: 'Warranty',
	[NS_JOB_STATUS_EMBED.BILLED_WITH_RETAINAGE]: 'Billed with Retainage',
};

export enum OPPORTUNITY_SOURCES_EMBED {
	PAVEMENT_SOFT_WEB_FORM = 'PavementSoft Web Form',
}

export enum PROPOSAL_PRICING_OPTIONS {
	ALL_ITEMS = 'allItems',
	LINE_ITEMS = 'lineItems',
	TOTAL_ONLY = 'totalOnly',
	NO_PRICING = 'noPricing',
}

export enum SIMPLE_PROPOSAL_PRICING_OPTIONS {
	ALL_ITEMS = 'allItems',
	LINE_ITEMS = 'lineItems',
	TOTAL_ONLY = 'totalOnly',
	NO_PRICING = 'noPricing',
}

export enum JOB_SCHEDULE_OPTIONS {
	UNSCHEDULED = 'unscheduled',
	SCHEDULED = 'scheduled',
	NEED_ATTENTION = 'need_attention',
}

export enum SMART_SEARCH_IDS {
	OPPORTUNITIES = 'opportunities',
	BIDS = 'bids',
	JOBS = 'jobs',
	CLIENTS = 'clients',
	PROPERTIES = 'properties',
	MANAGERS = 'managers',
}

export enum INVOICE_CREATION_MODE {
	BID_ITEMS = 'bid_items',
	COSTS_BLOCKS = 'costs_blocks',
}

export enum IMAGES_PER_PAGE {
	ONE = '1',
	TWO = '2',
	FOUR = '4',
}

export const DEFAULT_SALESPERSON_COLOR = '#d8d8d8';
//URL_DATE_FMT: 'DD.MM.YYYY-HH:mm:ss.ZZ',

export const DUMMY_OK_RESPONSE = { message: 'OK' };

export abstract class PlaceholderDefinition {
	label: string;
	html?: boolean;
}

export class SalespersonPlaceholdersMap {
	Salesperson: PlaceholderDefinition = { label: 'Salesperson' };
	Salesperson_Title: PlaceholderDefinition = { label: 'Salesperson Title' };
	Salesperson_Phone: PlaceholderDefinition = { label: 'Salesperson Phone' };
	Salesperson_Email: PlaceholderDefinition = { label: 'Salesperson Email' };
	Salesperson_Photo: PlaceholderDefinition = { label: 'Salesperson Photo', html: true };
	Salesperson_Signature: PlaceholderDefinition = { label: 'Salesperson Signature', html: true };
}

export class PlaceholdersMap extends SalespersonPlaceholdersMap {
	Company: PlaceholderDefinition = { label: 'Company' };
	// tslint:disable-next-line:variable-name
	Company_Phone: PlaceholderDefinition = { label: 'Company Phone' };
	License_Number: PlaceholderDefinition = { label: 'License Number' };
	Fuel_Price_Reference_Page: PlaceholderDefinition = { label: 'Fuel Price Reference Page', html: true };
	Delay_Rate: PlaceholderDefinition = { label: 'Delay Rate' };
	Manager: PlaceholderDefinition = { label: 'Manager' };
	Property: PlaceholderDefinition = { label: 'Property' };
	Client: PlaceholderDefinition = { label: 'Client' };
	Client_Billing_Contact: PlaceholderDefinition = { label: 'Client Billing Contact ' };
	Client_Billing_Address: PlaceholderDefinition = { label: 'Client Billing Address ' };
	Client_Billing_Email: PlaceholderDefinition = { label: 'Client Billing Email ' };
	// tslint:disable-next-line:variable-name
	Manager_Phone: PlaceholderDefinition = { label: 'Manager Phone' };
	DocuSignHere: PlaceholderDefinition = { label: 'DocuSign signer placeholder', html: true };
	DocuDateSigned: PlaceholderDefinition = { label: 'DocuSign date signed placeholder', html: true };
	Total: PlaceholderDefinition = { label: 'Total' };
	Optional_Total: PlaceholderDefinition = { label: 'Optional Total' };
	// tslint:disable-next-line:variable-name
	Total_Words: PlaceholderDefinition = { label: 'Total Words' };
	Optional_Total_Words: PlaceholderDefinition = { label: 'Optional Total Words' };
	// tslint:disable-next-line:variable-name
	Tax_Status: PlaceholderDefinition = { label: 'Tax Status Explanation' };
	// tslint:disable-next-line:variable-name
	Job_No: PlaceholderDefinition = { label: 'Job #' };
	// tslint:disable-next-line:variable-name
	Bid_Item_Titles: PlaceholderDefinition = { label: 'Bid items titles' };
	Optional_Bid_Item_Titles: PlaceholderDefinition = { label: 'Optional Bid items titles' };
	// tslint:disable-next-line:variable-name
	Bid_Item_Titles_Table: PlaceholderDefinition = { label: 'Bid items titles table', html: true };
	Optional_Bid_Item_Titles_Table: PlaceholderDefinition = { label: 'Optional Bid items titles table', html: true };
	// tslint:disable-next-line:variable-name
	Bid_item_Summary: PlaceholderDefinition = { label: 'Bid items summary', html: true };
	Optional_Bid_item_Summary: PlaceholderDefinition = { label: 'Optional Bid items summary', html: true };
	// tslint:disable-next-line:variable-name
	Bid_item_Unit_Cost_Summary: PlaceholderDefinition = { label: 'Bid items unit cost summary', html: true };
	Optional_Bid_item_Unit_Cost_Summary: PlaceholderDefinition = { label: 'Optional Bid items unit cost summary', html: true };
	// tslint:disable-next-line:variable-name
	Page_Break: PlaceholderDefinition = { label: 'Page break', html: true };
}

export class EventPlaceholdersMap {
	// tslint:disable-next-line:variable-name
	Event_Start_Date: PlaceholderDefinition = { label: 'Event start date' };
	// tslint:disable-next-line:variable-name
	Event_Start_Time: PlaceholderDefinition = { label: 'Event start time' };
}

export class BidLostEmailPlaceholdersMap {
	// tslint:disable-next-line:variable-name
	BidLostSurveyLink: PlaceholderDefinition = { label: 'Survey Link' };
}

export class JobPaidInFullEmailPlaceholdersMap {
	// tslint:disable-next-line:variable-name
	Paid_Survey: PlaceholderDefinition = { label: 'Survey Link' };
}

export class MassEmailPlaceholdersMap {
	Link_To_Proposal: PlaceholderDefinition = { label: 'Link to Proposal' };
}

export enum QB_DESKTOP_REQUEST {
	CREATE_CUSTOMER = 'CREATE_CUSTOMER',
	CREATE_ACCOUNT = 'CREATE_ACCOUNT',
	CREATE_ITEM = 'CREATE_ITEM',
	CREATE_INVOICE = 'CREATE_INVOICE',
	CREATE_TERMS = 'CREATE_TERMS',
	CREATE_EMPLOYEE = 'CREATE_EMPLOYEE',
	ADD_EMPLOYEE_TO_SALESREP = 'ADD_EMPLOYEE_TO_SALESREP',
	CREATE_ESTIMATE = 'CREATE_ESTIMATE',
}

export const BID_ITEM_PLACEHOLDERS = [
	{ id: 'totalCost', label: 'Price' },
	{ id: 'asphalt.amount.first', label: 'Asphalt tons (first block)' },
	{ id: 'asphalt.amount.sum', label: 'Asphalt tons sum' },
	{ id: 'asphalt.depth.first', label: 'Asphalt depth (first block)' },
	{ id: 'asphalt.depth.second', label: 'Asphalt depth (second block)' },
	{ id: 'asphalt.depth.third', label: 'Asphalt depth (third block)' },
	{ id: 'asphalt.depth.sum', label: 'Asphalt depth sum' },
	{ id: 'asphalt.area.first', label: 'Asphalt area (first block)' },
	{ id: 'asphalt.area.second', label: 'Asphalt area (second block)' },
	{ id: 'asphalt.area.third', label: 'Asphalt area (third block)' },
	{ id: 'asphalt.meta.areaCalculator.area', label: 'Asphalt area table (first block)' },
	{ id: 'asphalt.area.sum', label: 'Asphalt area sum' },
	{ id: 'crackfill.length.sum', label: 'Crackfill length sum' },
	{ id: 'crackfill.length.first', label: 'Crackfill length (first block)' },
	{ id: 'stripings.table', label: 'Stripings table' },
	{ id: 'thermo.table', label: 'Thermo table' },
	{ id: 'signage.table', label: 'Signage table' },
	{ id: 'sealer.area.first', label: 'Sealer area (first block)' },
	{ id: 'sealer.area.sum', label: 'Sealer area sum' },
	{ id: 'add_costs.table', label: 'Additional cost table' },
	{ id: 'subcontractors.table', label: 'Subcontractors table' },
	{ id: 'concrete.meta.areaCalculator.area', label: 'Concrete area table (first block)' },
	{ id: 'concrete.sap.depth.first', label: 'Flat Work depth (first block)' },
	{ id: 'concrete.sap.area.first', label: 'Flat Work area (first block)' },
	{ id: 'concrete.sap.area.sum', label: 'Flat Work area sum' },
	{ id: 'concrete.sap.standardIdLabel.first', label: 'Flat Work standard (first block)' },
	{ id: 'concrete.cg.area.first', label: 'Curb & Gutter length (first block)' },
	{ id: 'concrete.cg.area.sum', label: 'Curb & Gutter length (sum)' },
	{ id: 'concrete.cg.standardIdLabel.first', label: 'Curb & Gutter standard (first block)' },
	{ id: 'concrete.cylinders.depth.first', label: 'Cylinders depth (first block)' },
	{ id: 'concrete.cylinders.area.first', label: 'Cylinders area (first block)' },
	{ id: 'concrete.cylinders.area.sum', label: 'Cylinders area sum' },
	{ id: 'concrete.cylinders.standardIdLabel.first', label: 'Cylinders standard (first block)' },
	{ id: 'stone.depth.first', label: 'Stone depth (first block)' },
	{ id: 'stone.area.first', label: 'Stone area (first block)' },
	{ id: 'stone.area.sum', label: 'Stone area sum' },
];

export const DAILY_LOCK_TIME = 30 * 1000;

export const DAILY_REQUEST_TIME = 20 * 1000;

export const DAILY_USER_TIMER = 10 * 60 * 1000;

export const DAILY_RELOAD_AFTER_ERROR = 2 * 1000;

export enum DAILY_LOCK_REQUEST {
	daily_check_status_and_lock = 'daily_check_status_and_lock',
	daily_check_status = 'daily_check_status',
}

export enum DAILY_LOCK_STATES {
	daily_is_locked = 'daily_is_locked',
	daily_is_changed = 'daily_is_changed',
	daily_is_able_to_lock = 'daily_is_able_to_lock',
}

export const DocumentBaseBucket = 'documents';

export enum ENTITY_DOCUMENTS_INTERNAL_BUCKETS {
	invoices = 'invoices',
	contracts = 'contracts',
	materials = 'materials',
	archive = 'archive',
}

export enum ENTITY_DOCUMENTS_VTF_BUCKETS {
	maps = 'maps',
	presight_photos = 'presight_photos',
	work_complete_photos = 'work_complete_photos',
	work_log = 'work_log',
	permits = 'permits',
	tickets = 'tickets',
}

export const ENTITY_DOCUMENTS_DEFINITIONS: {
	[key in ENTITY_DOCUMENTS_INTERNAL_BUCKETS | ENTITY_DOCUMENTS_VTF_BUCKETS | typeof DocumentBaseBucket];
} = {
	[ENTITY_DOCUMENTS_INTERNAL_BUCKETS.invoices]: 'Invoices',
	[ENTITY_DOCUMENTS_INTERNAL_BUCKETS.contracts]: 'Contracts',
	[ENTITY_DOCUMENTS_INTERNAL_BUCKETS.materials]: 'Materials',
	[ENTITY_DOCUMENTS_INTERNAL_BUCKETS.archive]: 'Archive Documents',
	[ENTITY_DOCUMENTS_VTF_BUCKETS.maps]: 'Maps',
	[ENTITY_DOCUMENTS_VTF_BUCKETS.presight_photos]: 'Presight Photos',
	[ENTITY_DOCUMENTS_VTF_BUCKETS.work_complete_photos]: 'Work Complete Photos',
	[ENTITY_DOCUMENTS_VTF_BUCKETS.work_log]: 'Work Log',
	[ENTITY_DOCUMENTS_VTF_BUCKETS.permits]: 'Permits',
	[ENTITY_DOCUMENTS_VTF_BUCKETS.tickets]: 'Tickets',
	[DocumentBaseBucket]: 'Project Documents',
};

export const ASPHALT_INDEX_PLACEHOLDERS = [
	{ id: 'asphalt_index.CA.ON', label: 'Asphalt Index CA.ON' },
	{ id: 'asphalt_index.CA.QC', label: 'Asphalt Index CA.QC' },
	{ id: 'asphalt_index.CA.NS', label: 'Asphalt Index CA.NS' },
	{ id: 'asphalt_index.CA.NB', label: 'Asphalt Index CA.NB' },
	{ id: 'asphalt_index.CA.MB', label: 'Asphalt Index CA.MB' },
	{ id: 'asphalt_index.CA.BC', label: 'Asphalt Index CA.BC' },
	{ id: 'asphalt_index.CA.PE', label: 'Asphalt Index CA.PE' },
	{ id: 'asphalt_index.CA.SK', label: 'Asphalt Index CA.SK' },
	{ id: 'asphalt_index.CA.AB', label: 'Asphalt Index CA.AB' },
	{ id: 'asphalt_index.CA.NL', label: 'Asphalt Index CA.NL' },
	{ id: 'asphalt_index.CA.NT', label: 'Asphalt Index CA.NT' },
	{ id: 'asphalt_index.CA.YT', label: 'Asphalt Index CA.YT' },
	{ id: 'asphalt_index.CA.NU', label: 'Asphalt Index CA.NU' },
	{ id: 'asphalt_index.US.AL', label: 'Asphalt Index US.AL' },
	{ id: 'asphalt_index.US.AK', label: 'Asphalt Index US.AK' },
	{ id: 'asphalt_index.US.AS', label: 'Asphalt Index US.AS' },
	{ id: 'asphalt_index.US.AZ', label: 'Asphalt Index US.AZ' },
	{ id: 'asphalt_index.US.AR', label: 'Asphalt Index US.AR' },
	{ id: 'asphalt_index.US.CA', label: 'Asphalt Index US.CA' },
	{ id: 'asphalt_index.US.CO', label: 'Asphalt Index US.CO' },
	{ id: 'asphalt_index.US.CT', label: 'Asphalt Index US.CT' },
	{ id: 'asphalt_index.US.DE', label: 'Asphalt Index US.DE' },
	{ id: 'asphalt_index.US.DC', label: 'Asphalt Index US.DC' },
	{ id: 'asphalt_index.US.FM', label: 'Asphalt Index US.FM' },
	{ id: 'asphalt_index.US.FL', label: 'Asphalt Index US.FL' },
	{ id: 'asphalt_index.US.GA', label: 'Asphalt Index US.GA' },
	{ id: 'asphalt_index.US.GU', label: 'Asphalt Index US.GU' },
	{ id: 'asphalt_index.US.HI', label: 'Asphalt Index US.HI' },
	{ id: 'asphalt_index.US.ID', label: 'Asphalt Index US.ID' },
	{ id: 'asphalt_index.US.IL', label: 'Asphalt Index US.IL' },
	{ id: 'asphalt_index.US.IN', label: 'Asphalt Index US.IN' },
	{ id: 'asphalt_index.US.IA', label: 'Asphalt Index US.IA' },
	{ id: 'asphalt_index.US.KS', label: 'Asphalt Index US.KS' },
	{ id: 'asphalt_index.US.KY', label: 'Asphalt Index US.KY' },
	{ id: 'asphalt_index.US.LA', label: 'Asphalt Index US.LA' },
	{ id: 'asphalt_index.US.ME', label: 'Asphalt Index US.ME' },
	{ id: 'asphalt_index.US.MH', label: 'Asphalt Index US.MH' },
	{ id: 'asphalt_index.US.MD', label: 'Asphalt Index US.MD' },
	{ id: 'asphalt_index.US.MA', label: 'Asphalt Index US.MA' },
	{ id: 'asphalt_index.US.MI', label: 'Asphalt Index US.MI' },
	{ id: 'asphalt_index.US.MN', label: 'Asphalt Index US.MN' },
	{ id: 'asphalt_index.US.MS', label: 'Asphalt Index US.MS' },
	{ id: 'asphalt_index.US.MO', label: 'Asphalt Index US.MO' },
	{ id: 'asphalt_index.US.MT', label: 'Asphalt Index US.MT' },
	{ id: 'asphalt_index.US.NE', label: 'Asphalt Index US.NE' },
	{ id: 'asphalt_index.US.NV', label: 'Asphalt Index US.NV' },
	{ id: 'asphalt_index.US.NH', label: 'Asphalt Index US.NH' },
	{ id: 'asphalt_index.US.NJ', label: 'Asphalt Index US.NJ' },
	{ id: 'asphalt_index.US.NM', label: 'Asphalt Index US.NM' },
	{ id: 'asphalt_index.US.NY', label: 'Asphalt Index US.NY' },
	{ id: 'asphalt_index.US.NC', label: 'Asphalt Index US.NC' },
	{ id: 'asphalt_index.US.ND', label: 'Asphalt Index US.ND' },
	{ id: 'asphalt_index.US.MP', label: 'Asphalt Index US.MP' },
	{ id: 'asphalt_index.US.OH', label: 'Asphalt Index US.OH' },
	{ id: 'asphalt_index.US.OK', label: 'Asphalt Index US.OK' },
	{ id: 'asphalt_index.US.OR', label: 'Asphalt Index US.OR' },
	{ id: 'asphalt_index.US.PW', label: 'Asphalt Index US.PW' },
	{ id: 'asphalt_index.US.PA', label: 'Asphalt Index US.PA' },
	{ id: 'asphalt_index.US.PR', label: 'Asphalt Index US.PR' },
	{ id: 'asphalt_index.US.RI', label: 'Asphalt Index US.RI' },
	{ id: 'asphalt_index.US.SC', label: 'Asphalt Index US.SC' },
	{ id: 'asphalt_index.US.SD', label: 'Asphalt Index US.SD' },
	{ id: 'asphalt_index.US.TN', label: 'Asphalt Index US.TN' },
	{ id: 'asphalt_index.US.TX', label: 'Asphalt Index US.TX' },
	{ id: 'asphalt_index.US.UT', label: 'Asphalt Index US.UT' },
	{ id: 'asphalt_index.US.VT', label: 'Asphalt Index US.VT' },
	{ id: 'asphalt_index.US.VI', label: 'Asphalt Index US.VI' },
	{ id: 'asphalt_index.US.VA', label: 'Asphalt Index US.VA' },
	{ id: 'asphalt_index.US.WA', label: 'Asphalt Index US.WA' },
	{ id: 'asphalt_index.US.WV', label: 'Asphalt Index US.WV' },
	{ id: 'asphalt_index.US.WI', label: 'Asphalt Index US.WI' },
	{ id: 'asphalt_index.US.WY', label: 'Asphalt Index US.WY' },
];

export enum POSTING_PERIODS {
	not_specified = 'not_specified',
	weekly = 'weekly',
	biweekly = 'biweekly',
	monthly = 'monthly',
}

export const POSTING_PERIODS_DEFINITIONS: { [key in POSTING_PERIODS]: string } = {
	not_specified: 'Not specified',
	weekly: 'Weekly',
	biweekly: 'Bi-Weekly',
	monthly: 'Monthly',
};

export const EVENT_COSTS_BLOCKS_STATS = [
	COSTS_BLOCKS.asphalt,
	COSTS_BLOCKS.cement,
	COSTS_BLOCKS.tac,
	COSTS_BLOCKS.crackfill,
	COSTS_BLOCKS.sealer,
	COSTS_BLOCKS.mastic,
	COSTS_BLOCKS.thermo,
	COSTS_BLOCKS.stone,
	COSTS_BLOCKS.concrete,
	COSTS_BLOCKS.stripings,
	COSTS_BLOCKS.trucking,
];

/**
 * standard - default, each group should have this status, tax is calculated based on bidItem price
 * commercial_remodel - tax is calculated based on bidItem price
 * commercial_new, residential - tax is calculated based on material prices (i.e. asphalt, tac, crackfill, sealer, mastic, stone, concrete, add_costs, stripings, signage, thermo), tax is displayed as price for the client
 * commercial_remodel_resale, tax_exempt - tax is calculated as commercial_remodel or isn't calculated at all
 */
export enum BID_TAX_STATUS {
	standard = 'standard',
	commercial_remodel = 'commercial_remodel',
	commercial_new = 'commercial_new',
	residential = 'residential',
	commercial_remodel_resale = 'commercial_remodel_resale',
	tax_exempt = 'tax_exempt',
}

export const BidTaxStatusLabel: { [key in BID_TAX_STATUS]: string } = {
	[BID_TAX_STATUS.standard]: 'Standard',
	[BID_TAX_STATUS.commercial_remodel]: 'Commercial Remodel',
	[BID_TAX_STATUS.commercial_new]: 'Commercial New',
	[BID_TAX_STATUS.residential]: 'Residential',
	[BID_TAX_STATUS.commercial_remodel_resale]: 'Commercial Remodel Resale',
	[BID_TAX_STATUS.tax_exempt]: 'Tax Exempt',
};

export const BidTaxStatusHint: { [key in BID_TAX_STATUS]: string } = {
	[BID_TAX_STATUS.standard]: '- tax on entire job',
	[BID_TAX_STATUS.commercial_remodel]: '- tax on entire job',
	[BID_TAX_STATUS.commercial_new]: '(homes, condos, APTs) - tax on materials only',
	[BID_TAX_STATUS.residential]: '- tax on materials only',
	[BID_TAX_STATUS.commercial_remodel_resale]: '- GC charges tax to owner',
	[BID_TAX_STATUS.tax_exempt]: '(church, city, county, public school)',
};

export enum BID_TAX_STATUS_GROUPS {
	texas = 'texas',
}

export const BidTaxStatusGroupLabel: { [key in BID_TAX_STATUS_GROUPS]: string } = {
	[BID_TAX_STATUS_GROUPS.texas]: 'Texas',
};

export const BID_STATUS_TAX_GROUP_STATUSES: { [key in BID_TAX_STATUS_GROUPS]: BID_TAX_STATUS[] } = {
	[BID_TAX_STATUS_GROUPS.texas]: [
		BID_TAX_STATUS.standard,
		BID_TAX_STATUS.commercial_remodel,
		BID_TAX_STATUS.commercial_new,
		BID_TAX_STATUS.residential,
		BID_TAX_STATUS.commercial_remodel_resale,
		BID_TAX_STATUS.tax_exempt,
	],
};

export const isBidTaxStatusWithWaiveOption = (status: BID_TAX_STATUS) => [BID_TAX_STATUS.commercial_remodel_resale, BID_TAX_STATUS.tax_exempt].includes(status);

export const isBidTaxStatusWithMaterialsTax = (status: BID_TAX_STATUS) => [BID_TAX_STATUS.commercial_new, BID_TAX_STATUS.residential].includes(status);

export enum VERSION_REVIEW_STATUS {
	no_need = 'no_need',
	waiting_review = 'waiting_review',
	review_completed = 'review_completed',
}

export const VersionReviewStatusLabel: { [key in VERSION_REVIEW_STATUS]: string } = {
	[VERSION_REVIEW_STATUS.no_need]: 'No need',
	[VERSION_REVIEW_STATUS.waiting_review]: 'Waiting Review',
	[VERSION_REVIEW_STATUS.review_completed]: 'Review Completed',
};
export function getBidStatusPlaceholder(status: BID_TAX_STATUS, companyName: string) {
	switch (status) {
		case BID_TAX_STATUS.commercial_remodel:
			return 'This is a commercial remodel project. Sales tax is due as presented on this invoice.';
		case BID_TAX_STATUS.commercial_remodel_resale:
			return 'This is a lump sum commercial remodel project for resale. To avoid paying sales tax, please provide a properly completed Texas Resale Certificate.';
		case BID_TAX_STATUS.commercial_new:
			return `This is a new construction lump sum project. No sales tax will be charged to you. To perform the scope of described above, ${companyName} will pay all sales tax for materials, equipment, and services needed.`;
		case BID_TAX_STATUS.residential:
			return `This is a residential lump sum project. No sales tax will be charged to you. To perform the scope of described above, ${companyName} will pay all sales tax for materials, equipment, and services needed.`;
		case BID_TAX_STATUS.tax_exempt:
			return 'This project is classified as Tax Exempt. No sales tax will be charged to you upon receipt of a valid Texas Sales and Use Tax Exemption Certificate.';
	}
	return '';
}

export enum LEAD_STATUS {
	NEW = 'new',
	DECLINED = 'declined',
	OPPORTUNITY = 'opportunity',
}

export const REVIEWED_LEAD_STATUSES = [LEAD_STATUS.DECLINED, LEAD_STATUS.OPPORTUNITY];

export const LeadStatusLabel: Record<LEAD_STATUS, string> = {
	[LEAD_STATUS.NEW]: 'New',
	[LEAD_STATUS.DECLINED]: 'Declined',
	[LEAD_STATUS.OPPORTUNITY]: 'Opportunity',
};

export enum BID_APPROVAL_PROFIT_THRESHOLD_TYPE {
	per_bid_item = 'per_bid_item',
	per_bid = 'per_bid',
}

export const BidApprovalProfitThresholdTypeLabel: Record<BID_APPROVAL_PROFIT_THRESHOLD_TYPE, string> = {
	[BID_APPROVAL_PROFIT_THRESHOLD_TYPE.per_bid_item]: 'Per Bid Item',
	[BID_APPROVAL_PROFIT_THRESHOLD_TYPE.per_bid]: 'Per Bid',
};

export enum ContactPreference {
	NO_CONTACT = 'no_contact',
	BID_JOB = 'bid_job',
	CAMPAIGNS_PROMOTIONS = 'campaigns_promotions',
}

export const contactPreferenceToLabel: Record<ContactPreference, string> = {
	[ContactPreference.NO_CONTACT]: 'No Contact',
	[ContactPreference.BID_JOB]: 'Bid/Job Only',
	[ContactPreference.CAMPAIGNS_PROMOTIONS]: 'Campaigns/Promotions',
};

export const CONTACT_PREFERENCES = Object.values(ContactPreference).map((contactPreference) => ({
	id: contactPreference,
	label: contactPreferenceToLabel[contactPreference],
}));

export enum CalendarEventTileColorOption {
	CREW = 'crew',
	CATEGORY = 'category',
	SALESPERSON = 'salesperson',
}

export const calendarEventTileColorOptionToLabel: Record<CalendarEventTileColorOption, string> = {
	[CalendarEventTileColorOption.CREW]: 'Crew',
	[CalendarEventTileColorOption.CATEGORY]: 'Category',
	[CalendarEventTileColorOption.SALESPERSON]: 'Salesperson',
};

export const CALENDAR_EVENT_TILE_COLOR_OPTIONS = Object.values(CalendarEventTileColorOption).map((option) => ({
	id: option,
	name: calendarEventTileColorOptionToLabel[option],
}));

export enum OfficeSequenceType {
	SEPARATE = 'separate',
	SEQUENCED = 'sequenced',
	BID_AND_JOB_SEQUENCED = 'bid_and_job_sequenced',
}

export const officeSequenceTypeToLabel: Record<OfficeSequenceType, string> = {
	[OfficeSequenceType.SEPARATE]: 'All Separate',
	[OfficeSequenceType.SEQUENCED]: 'All Sequenced',
	[OfficeSequenceType.BID_AND_JOB_SEQUENCED]: 'Only Bid and Job Sequenced',
};

export const OFFICE_SEQUENCE_TYPE_OPTIONS = Object.values(OfficeSequenceType).map((type) => ({
	id: type,
	label: officeSequenceTypeToLabel[type],
}));
