import { ChangeDetectorRef, Inject, Injectable, InjectionToken } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PersistenceService, GridSettings, GridName } from './persistence.service';
import { LinksInNewTabsService } from './links-in-new-tabs.service';
import { ModalService } from './modal-service.service';
import { ColumnOrderDlgComponent } from './components/column-order-dlg/column-order-dlg.component';

export interface ColumnInfo {
	key: string;
	label: string;
	isHiddenByDefault?: boolean;
	[key: PropertyKey]: any;
}

export interface GridSettingsServiceConfig {
	gridName: GridName;
	columnsInfo?: ColumnInfo[];
}

export const GRID_SETTINGS_SERVICE_CONFIG_TOKEN = new InjectionToken<GridSettingsServiceConfig>('GRID_SETTINGS_SERVICE_CONFIG_TOKEN');

@Injectable()
export class GridSettingsService {
	private gridSettings: GridSettings;

	private defaultColumnsOrder = [];
	private hiddenByDefaultColumns: string[] = [];
	private columnToLabel: Record<string, string> = {};

	private columnToIndexMap = new Map();

	constructor(
		private cd: ChangeDetectorRef,
		private persistenceService: PersistenceService,
		private linksInNewTabsService: LinksInNewTabsService,
		private modalService: ModalService,
		@Inject(GRID_SETTINGS_SERVICE_CONFIG_TOKEN) private config: GridSettingsServiceConfig
	) {
		this.gridSettings = persistenceService.getGridSettings(this.config.gridName);

		if (this.config.columnsInfo) {
			this.defaultColumnsOrder = this.config.columnsInfo.map(({ key }) => key);
			this.hiddenByDefaultColumns = this.config.columnsInfo.filter(({ isHiddenByDefault }) => isHiddenByDefault).map(({ key }) => key);
			this.columnToLabel = Object.fromEntries(this.config.columnsInfo.map(({ key, label }) => [key, label]));
			this.refreshColumnToIndexMap();
		}
	}

	public get linkTarget(): string {
		return this.linksInNewTabsService.linkTarget;
	}

	public get pageSize(): number {
		return this.gridSettings._pageSize ?? 25;
	}

	public set pageSize(value: number) {
		this.gridSettings._pageSize = value;
		this.persistenceService.setGridSettings(this.config.gridName, this.gridSettings);
	}

	public persistColumnVisibility(column: string, isHidden: boolean) {
		this.gridSettings[column] = this.gridSettings[column] ? { ...this.gridSettings[column], hidden: isHidden } : { hidden: isHidden };

		if (this.gridSettings._columnOrder) {
			if (isHidden) {
				this.removeColumnFromColumnOrder(column);
			} else {
				this.addColumnToColumnOrder(column);
			}
			this.refreshColumnToIndexMap();
		}

		return this.persistenceService.setGridSettings(this.config.gridName, this.gridSettings);
	}

	public getIsColumnHidden(column: string) {
		return this.gridSettings[column]?.hidden ?? this.hiddenByDefaultColumns.includes(column);
	}

	public getColumnOrder(column: string) {
		return this.columnToIndexMap.get(column) ?? -1;
	}

	public openColumnsOrderDlg() {
		const columns = this.getVisibleColumnsOrder();

		this.modalService
			.open(
				ColumnOrderDlgComponent,
				{
					columnToLabel: this.columnToLabel,
					columns,
				},
				false
			)
			.onResult()
			.pipe(
				tap((columnsOrder) => {
					this.persistColumnsOrder(columnsOrder);
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	private refreshColumnToIndexMap() {
		this.columnToIndexMap.clear();
		this.gridSettings._columnOrder?.forEach((column, index) => {
			this.columnToIndexMap.set(column, index);
		});
	}

	private removeColumnFromColumnOrder(column: string): void {
		if (!this.config.columnsInfo) {
			return;
		}
		this.gridSettings._columnOrder = this.gridSettings._columnOrder.filter((columnName) => columnName !== column);
	}

	private addColumnToColumnOrder(column: string) {
		if (!this.config.columnsInfo) {
			return;
		}
		if (this.gridSettings._columnOrder.includes(column)) {
			return;
		}

		const index = this.defaultColumnsOrder.indexOf(column);
		this.gridSettings._columnOrder.splice(index, 0, column);
	}

	private persistColumnsOrder(columns: string[]) {
		this.gridSettings._columnOrder = columns;
		this.persistenceService.setGridSettings(this.config.gridName, this.gridSettings);
		this.refreshColumnToIndexMap();
	}

	private getVisibleColumnsOrder() {
		const columnOrder = this.gridSettings?._columnOrder ?? [];
		const visibleColumns = this.defaultColumnsOrder.filter((column) => !this.getIsColumnHidden(column));
		const orderedColumns = visibleColumns.filter((column) => columnOrder.includes(column));
		const nonOrderedColumns = visibleColumns.filter((column) => !columnOrder.includes(column));
		orderedColumns.sort((a, b) => {
			const orderA = columnOrder.indexOf(a);
			const orderB = columnOrder.indexOf(b);
			return orderA - orderB;
		});

		return [...nonOrderedColumns, ...orderedColumns];
	}
}
