import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardExecutiveDailyStats } from '@pss2-shared/apiModels';
import { calculateRatioPercents, roundValue } from '@pss2-shared/utilFuncs';
import { ChartComponent, ITextRenderEventArgs } from '@syncfusion/ej2-angular-charts';
import {
	addWeeks,
	areIntervalsOverlapping,
	differenceInDays,
	eachDayOfInterval,
	eachWeekendOfMonth,
	endOfDay,
	endOfWeek,
	getDaysInMonth,
	getMonth,
	getYear,
	isSaturday,
	isWeekend,
	isWithinInterval,
	max,
	min,
	startOfDay,
	startOfWeek,
	subDays,
	subSeconds,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { combineLatest, tap } from 'rxjs';
import { AuthState } from 'src/app/auth/store/auth.store';
import { BusinessDivisionsHttpService } from 'src/app/company-settings/services/business-divisions-http.service';
import { UsersHttpService } from 'src/app/company-settings/services/users-http.service';
import { DashboardDatesService, DashboardInterval } from 'src/app/dashboard/services/dashboard-dates-service';
import { Modal } from 'src/app/shared/modal-service-models/modal.model';
import { formatDateUS, formatDateUSTz, parseUSDate, parseUSDateTz } from 'src/app/shared/utils/dateUtils';
import { formatMoney } from 'src/app/shared/utils/numberUtils';
import { DashboardsHttpService } from 'src/app/workflow/services/dashboards-http.service';

type ExecutiveDailyStatsDashboardInput = {
	isPopupManuallyOpen: boolean;
};

type TableStats = {
	goal: string;
	scheduled: {
		value: string;
		color: string;
		percentValue: number;
	};
	dailiesRevenue: {
		value: string;
		color: string;
		percentValue: number;
	};
	dailiesExpenses: string;
	dailiesProfit: {
		value: string;
		color: string;
		percentValue: number;
	};
	bids: {
		count: number;
		proposalTotal: string;
	};
	jobs: {
		count: number;
		proposalTotal: string;
	};
	incidents: number;
};

enum DashboardDateRangeFilterOptions {
	yesterday = 'yesterday',
	current_week = 'current_week',
}

const LeftPartTitle = {
	[DashboardDateRangeFilterOptions.current_week]: 'Current Week',
	[DashboardDateRangeFilterOptions.yesterday]: 'Yesterday',
};

@Component({
	selector: 'app-executive-daily-stats-dashboard',
	templateUrl: './executive-daily-stats-dashboard.component.html',
	styleUrls: ['./executive-daily-stats-dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExecutiveDailyStatsDashboard extends Modal implements OnInit {
	_chartComponent: ChartComponent;
	@ViewChild(ChartComponent)
	set chartComponent(chartComponent) {
		if (this._chartComponent != null && this._chartComponent == chartComponent) return;
		this._chartComponent = chartComponent;
		this.fillChartData();
	}

	get chartComponent() {
		return this._chartComponent;
	}

	isPopupManuallyOpen: boolean = false;
	isLoading = false;
	isSaveTimeRequestLoading = false;
	data: DashboardExecutiveDailyStats;
	divisions = []

	leftPartStats: TableStats = null;
	todayStats: TableStats = null;

	todayDate: Date;
	yesterdayDate: Date;

	selectedDateRange = DashboardDateRangeFilterOptions.yesterday;
	dateOptions = DashboardDateRangeFilterOptions;
	LeftPartTitle = LeftPartTitle;

	user = null;

	weekStartsOn: 0 | 1 = 0;
	currentDashboardInterval = DashboardInterval.week;
	goalsColor = '#f9ab42';
	revenueColor = '#1a77b5';

	primaryXAxis = {
		interval: 1,
		valueType: 'Category',
	};

	primaryYAxis = {
		labelFormat: '${value}',
	};

	legendSettings = {
		visible: true,
		position: 'Right',
		width: '250',
	};

	tooltip = {
		enable: true,
		format: '${point.x} : <b>${point.tooltip}',
	};

	zoom = {
		enableMouseWheelZooming: true,
		enablePinchZooming: true,
		enableSelectionZooming: true,
		enablePan: true,
		enableScrollbar: true,
		enableDeferredZooming: true,
		mode: 'XY',
	};

	columnDaysData = [];
	columnEventData = [];
	lineGoalData = [];

	daysColumnColor = '#1a77b5';
	stackingColumnMarker = {
		dataLabel: { visible: true, name: 'dataLabel' },
	};
	lineMarker = {
		visible: true,
		width: 10,
		height: 10,
		shape: 'Circle',
	};

	constructor(
		private cd: ChangeDetectorRef,
		private dashboardsHttpService: DashboardsHttpService,
		private dashboardDatesService: DashboardDatesService,
		private store: Store,
		private userHttpService: UsersHttpService,
		private businessDivisionsHttpService: BusinessDivisionsHttpService
	) {
		super();
	}

	onInjectInputs(inputs: ExecutiveDailyStatsDashboardInput): void {
		this.isPopupManuallyOpen = inputs.isPopupManuallyOpen;
	}

	ngOnInit(): void {
		this.store
			.select(AuthState.companySharedSettings)
			.pipe(
				tap((settings) => {
					this.weekStartsOn = settings?.isWeekStartsOnMonday ? 1 : 0;
				})
			)
			.subscribe();
		this.user = this.store.selectSnapshot(AuthState.user);

		this.todayDate = startOfDay(utcToZonedTime(new Date(), this.user.timezone));
		this.yesterdayDate = startOfDay(utcToZonedTime(subDays(new Date(), 1), this.user.timezone));

		this.isLoading = true;

		const executiveStats$ = this.dashboardsHttpService.getExecutiveDailyStats();
		const divisions$ = this.businessDivisionsHttpService.getAll({ showInactive: false });

		combineLatest([executiveStats$, divisions$]).subscribe(([executiveStats, divisions]) => {
			this.data = executiveStats;
			this.divisions = divisions;
			this.isLoading = false;
			this.processData();
			this.cd.markForCheck();
		});
	}

	processData() {
		if (!this.data) return;

		this.todayStats = this.calculateStatsInRange(startOfDay(this.todayDate), endOfDay(this.todayDate));
		this.leftPartStats = this.calculateStatsInRange(startOfDay(this.yesterdayDate), endOfDay(this.yesterdayDate));

		this.fillChartData();
	}

	calculateStatsInRange(startDate: Date, endDate: Date) {
		const periodInterval = { start: startDate, end: endDate };

		const isWithinRange = (date: string) => isWithinInterval(new Date(date), periodInterval);

		const periodDailies = this.data.days.filter((day) => isWithinRange(day.date));
		const incidentsCount = this.data.incidents.filter((incident) => isWithinRange(incident.date)).length;

		const bidsInRange = this.data.bids.filter((bid) => isWithinRange(bid.proposalDate));
		const bidsProposalTotal = roundValue(
			bidsInRange.reduce((sum, d) => sum + d.proposalTotal, 0),
			2
		);

		const jobsInRange = this.data.jobs.filter((job) => isWithinRange(job.contractDate));
		const jobsProposalTotal = roundValue(
			jobsInRange.reduce((sum, d) => sum + d.proposalTotal, 0),
			2
		);

		const eventsInRange = this.data.events.filter((event) =>
			areIntervalsOverlapping({ start: new Date(event.startTime), end: new Date(event.endTime) }, periodInterval)
		);

		let scheduled = 0;
		eventsInRange.forEach((e) => {
			const startTimeRange = utcToZonedTime(max([new Date(e.startTime), zonedTimeToUtc(startDate, this.user.timezone)]), this.user.timezone);
			const endTimeRange = utcToZonedTime(min([new Date(e.endTime), zonedTimeToUtc(endDate, this.user.timezone)]), this.user.timezone);
			const duration = differenceInDays(startOfDay(subSeconds(endTimeRange, 1)), startOfDay(startTimeRange)) + 1;
			const proposalPrice = e.proposalPricePerDay * duration;

			scheduled += proposalPrice;
		});

		const goal = this.calculateRevenueGoal(startDate, endDate, this.divisions, 'revenueGoals');
		const profitGoal = this.calculateRevenueGoal(startDate, endDate, this.divisions, 'profitGoals');
		const dailiesRevenueValue = roundValue(
			periodDailies.reduce((sum, d) => sum + d.revenue, 0),
			2
		);
		const dailiesExpenses = roundValue(
			periodDailies.reduce((sum, d) => sum + d.expense, 0),
			2
		);
		const dailiesProfitValue = roundValue(dailiesRevenueValue - dailiesExpenses, 2);

		return {
			dailiesExpenses: formatMoney(dailiesExpenses),
			dailiesRevenue: {
				value: formatMoney(dailiesRevenueValue),
				color: this.getValueColor(goal, dailiesRevenueValue),
				percentValue: goal == 0 ? 0 : calculateRatioPercents(goal, dailiesRevenueValue),
			},
			dailiesProfit: {
				value: formatMoney(dailiesProfitValue),
				color: this.getValueColor(profitGoal, dailiesProfitValue),
				percentValue: dailiesRevenueValue == 0 ? 0 : calculateRatioPercents(dailiesRevenueValue, dailiesProfitValue),
			},
			goal: formatMoney(goal),
			incidents: incidentsCount,
			bids: {
				count: bidsInRange.length,
				proposalTotal: formatMoney(bidsProposalTotal),
			},
			jobs: {
				count: jobsInRange.length,
				proposalTotal: formatMoney(jobsProposalTotal),
			},
			scheduled: {
				value: formatMoney(roundValue(scheduled, 2)),
				color: this.getValueColor(goal, scheduled),
				percentValue: goal == 0 ? 0 : calculateRatioPercents(goal, scheduled),
			},
		};
	}

	calculateRevenueGoal = (startDate: Date, endDate: Date, divisions, goals: 'revenueGoals' | 'profitGoals') => {
		const datesInRange = eachDayOfInterval({ start: startDate, end: endDate });

		const totalGoal = datesInRange.reduce((sum, date) => {
			const monthIndex = getMonth(date);
			const year = getYear(date);
			const monthDays = getDaysInMonth(date);

			const monthWeekendDates = eachWeekendOfMonth(date);
			let monthSaturdays = 0;
			let monthSundays = 0;
			monthWeekendDates.forEach((d) => {
				isSaturday(d) ? monthSaturdays++ : monthSundays++;
			});
			const workDaysInMonth = monthDays - monthSaturdays - monthSundays;

			const dailyGoal = divisions
				.map((division) => {
					const yearGoals = division.goals.find((goal) => goal.year === year);
					if (!yearGoals) {
						return 0;
					}

					const monthlyGoal = yearGoals[goals][monthIndex] || 0;
					const saturdayPercentDecimal = yearGoals.saturdaysPercentOfDayGoal[monthIndex] / 100;
					const sundayPercentDecimal = yearGoals.sundaysPercentOfDayGoal[monthIndex] / 100;

					const weightedDayGoal = monthlyGoal / (workDaysInMonth + monthSaturdays * saturdayPercentDecimal + monthSundays * sundayPercentDecimal);

					const saturdayGoal = weightedDayGoal * saturdayPercentDecimal;
					const sundayGoal = weightedDayGoal * sundayPercentDecimal;

					if (isWeekend(date)) {
						return isSaturday(date) ? saturdayGoal : sundayGoal;
					}

					return weightedDayGoal;
				})
				.reduce((sum, goal) => sum + goal, 0);

			return sum + dailyGoal;
		}, 0);

		return roundValue(totalGoal, 2);
	};

	getValueColor(goal: number, value: number) {
		return value >= goal ? 'green' : 'red';
	}

	fillChartData() {
		if (!this._chartComponent) return;

		this.columnDaysData = [];
		this.columnEventData = [];
		this.lineGoalData = [];

		this._chartComponent.clearSeries();

		const startDate = parseUSDateTz(formatDateUS(startOfWeek(new Date(), { weekStartsOn: this.weekStartsOn })), this.user.timezone);
		const startOfWeekEightWeeksFromNow = parseUSDateTz(
			formatDateUS(endOfWeek(addWeeks(utcToZonedTime(new Date(), this.user.timezone), 7), { weekStartsOn: this.weekStartsOn })),
			this.user.timezone
		);

		const dashboardIntervalPoints = this.dashboardDatesService.getDashboardIntervalPointsDateRange(
			this.user.timezone,
			this.currentDashboardInterval,
			formatDateUSTz(startDate, this.user.timezone),
			formatDateUSTz(startOfWeekEightWeeksFromNow, this.user.timezone)
		);

		this.columnDaysData.push({
			fill: this.daysColumnColor,
			dataSource: dashboardIntervalPoints.map((p) => ({
				label: p.label,
				startDate: p.startDate,
				endDate: p.endDate,
				revenue: 0,
				tooltip: '0',
				dataLabel: formatMoney(0),
			})),
		});

		let totalGoalRevenue = dashboardIntervalPoints.map((intervalPoint) => {
			const { startDate, endDate } = intervalPoint;
			const startDateObj = utcToZonedTime(startDate, this.user.timezone);
			const endDateObj = utcToZonedTime(endDate, this.user.timezone);

			return this.calculateRevenueGoal(startDateObj, endDateObj, this.divisions, 'revenueGoals');
		});

		this.lineGoalData.push({
			fill: this.goalsColor,
			dataSource: dashboardIntervalPoints.map((p, index) => ({
				label: p.label,
				startDate: p.startDate,
				endDate: p.endDate,
				revenue: roundValue(totalGoalRevenue[index], 2),
				tooltip: formatMoney(totalGoalRevenue[index]),
				dataLabel: formatMoney(totalGoalRevenue[index]),
			})),
		});

		this.columnEventData.push({
			fill: this.revenueColor,
			dataSource: dashboardIntervalPoints.map((p) => ({
				label: p.label,
				startDate: p.startDate,
				endDate: p.endDate,
				revenue: 0,
				tooltip: '0',
				dataLabel: formatMoney(0),
			})),
		});

		for (const intervalPoint of dashboardIntervalPoints) {
			const { label, startDate, endDate } = intervalPoint;

			const startDateObj = new Date(startDate);
			const endDateObj = new Date(endDate);

			const eventsInRange = this.data.events.filter((event) =>
				areIntervalsOverlapping({ start: new Date(event.startTime), end: new Date(event.endTime) }, { start: startDateObj, end: endDateObj })
			);

			let sum = 0;
			eventsInRange.forEach((e) => {
				const startTimeRange = utcToZonedTime(max([new Date(e.startTime), startDateObj]), this.user.timezone);
				const endTimeRange = utcToZonedTime(min([new Date(e.endTime), endDateObj]), this.user.timezone);
				const duration = differenceInDays(startOfDay(subSeconds(endTimeRange, 1)), startOfDay(startTimeRange)) + 1;
				const proposalPrice = e.proposalPricePerDay * duration;

				sum += proposalPrice;
			});

			const chart = this.columnEventData[0].dataSource.find((data) => data.label === label);
			chart.revenue = sum;
			chart.tooltip = formatMoney(sum);
		}

		this.chartComponent.addSeries([
			{
				type: 'StackingColumn',
				xName: 'label',
				yName: 'revenue',
				name: 'Scheduled Revenue',
				dataSource: this.columnEventData[0].dataSource,
				fill: this.revenueColor,
				tooltipMappingName: 'tooltip',
			},
			{
				type: 'Line',
				xName: 'label',
				yName: 'revenue',
				name: 'Total Goal',
				dataSource: this.lineGoalData[0].dataSource,
				fill: this.goalsColor,
				width: 2,
				tooltipMappingName: 'tooltip',
				marker: this.lineMarker as any,
			},
		]);

		this.cd.markForCheck();
	}

	changeDateRange() {
		if (this.selectedDateRange === this.dateOptions.yesterday) {
			this.leftPartStats = this.calculateStatsInRange(startOfDay(this.yesterdayDate), endOfDay(this.yesterdayDate));
		} else if (this.selectedDateRange === this.dateOptions.current_week) {
			const startOfWeekDate = startOfWeek(utcToZonedTime(new Date(), this.user.timezone), { weekStartsOn: this.weekStartsOn });
			const endOfWeekDate = endOfWeek(utcToZonedTime(new Date(), this.user.timezone), { weekStartsOn: this.weekStartsOn });

			this.leftPartStats = this.calculateStatsInRange(startOfWeekDate, endOfWeekDate);
		}
	}

	ok(): void {
		if (this.isPopupManuallyOpen) {
			this.close(null);
		} else {
			this.isSaveTimeRequestLoading = true;
			this.userHttpService
				.setUserAlerts({
					alerts: {
						executiveDailyStats: utcToZonedTime(new Date(), this.user.timezone).toISOString(),
					},
				})
				.subscribe(() => {
					this.isSaveTimeRequestLoading = false;
					this.close(null);
				});
		}
	}

	textRender(args: ITextRenderEventArgs): void {
		args.cancel = args.point.y === 0;
	}
}
