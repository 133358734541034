import { roundValue } from '../../../../../pss2-shared/utilFuncs';

export function formatNumberPattern(value: number, precision = 1) {
	let formattedValue = value;
	let postFix = '';

	if (value >= Math.pow(10, 3) && value < Math.pow(10, 6)) {
		formattedValue = value / Math.pow(10, 3);
		postFix = 'K';
	} else if (value >= Math.pow(10, 6)) {
		formattedValue = value / Math.pow(10, 6);
		postFix = 'M';
	}

	return `${roundValue(formattedValue, precision)}${postFix}`;
}

export function formatMoney(value: number, precision = 1) {
	return `$${formatNumberPattern(value, precision)}`;
}

export function formatPercentage(value: number, precision = 1) {
	return `${formatNumberPattern(value, precision)}%`;
}
