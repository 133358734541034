<div class="modal">
	<div class="modal-dialog modal-md" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-header">
				<button aria-label="Close" class="close" type="button" (click)="cancel()">
					<cds-icon shape="close"></cds-icon>
				</button>
				<h3 class="modal-title">Note</h3>
			</div>
			<form class="modal-body" clrForm clrLayout="vertical">
				<clr-textarea-container class="full-width-textarea">
					<textarea
						clrTextarea
						class="full-width-textarea"
						[formControl]="reasonOfRejectionControl"
						placeholder="Please write a reason why the request has been declined"
						[rows]="5">
					</textarea>
					<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
					<clr-control-error *clrIfError="'minlength'">It must be at most 500 characters!</clr-control-error>
					<clr-control-error *clrIfError="'maxlength'">It must be at most 500 characters!</clr-control-error>
				</clr-textarea-container>
			</form>
			<div class="modal-footer">
				<button class="btn btn-outline" type="button" (click)="cancel()">Cancel</button>
				<button class="btn btn-primary" type="button" (click)="ok()">Save</button>
			</div>
		</div>
	</div>
</div>
