import { Component, ChangeDetectionStrategy, Inject, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Validators, FormControl, FormArray, FormGroup } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';
import { Address } from '@pss2-shared/address';
import { Phone } from '@pss2-shared/phone';
import { COUNTRIES, STATES } from '@pss2-shared/countries';
import { DetailedPaveAmericaClientRequest, PaveAmericaClient } from '@pss2-shared/apiModels';
import { DECLINED_PAVE_AMERICA_CLIENT_REQUEST_STATUSES, paveAmericaClientRequestStatusToLabel } from '@pss2-shared/paveAmerica';
import { POPUP_DLG_DATA, PopupDlgOverlayRef } from 'src/app/shared/popup-dlg.service';
import { getAddressFormGroup } from 'src/app/shared/components/address/address.component';
import { PhonesComponent } from '../phones/phones.component';
import { arrayExactLength } from '../../utils/formUtils';
import { ModalService } from '../../modal-service.service';
import { PaveAmericaClientRequestReasonOfRejection } from '../pave-america-client-request-reason-of-rejection-dlg/pave-america-client-request-reason-of-rejection-dlg.component';

export type PaveAmericaClientRequestDlgComponentData =
	| {
			mode: 'create';
			clientId: number;
			paveAmericaClient: PaveAmericaClient;
	  }
	| {
			mode: 'view';
			paveAmericaClientRequest: DetailedPaveAmericaClientRequest;
			isAdmin: boolean;
			canApprove: boolean;
			canUpdate: boolean; // if can update = can decline
	  };

export type PaveAmericaClientRequestDlgComponentSavePayload = {
	name: string;
	email: string;
	address: Address;
	phone: Phone;
	notes: string;
};

export type PaveAmericaClientRequestDlgComponentOutput =
	| { type: 'create'; payload: PaveAmericaClientRequestDlgComponentSavePayload; clientId: number; paveAmericaClientId?: number }
	| { type: 'update'; payload: PaveAmericaClientRequestDlgComponentSavePayload }
	| { type: 'approve'; payload: PaveAmericaClientRequestDlgComponentSavePayload }
	| { type: 'decline'; reasonOfRejection: string };

@Component({
	selector: 'app-pave-america-client-request-dlg',
	templateUrl: './pave-america-client-request-dlg.component.html',
	styleUrls: ['./pave-america-client-request-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaveAmericaClientRequestDlgComponent implements OnInit, AfterViewInit {
	@ViewChild('phones', { static: true })
	phones: PhonesComponent;

	isUpdateRequest: boolean;
	showPaveAmericaClientColumn: boolean;

	form: FormGroup;
	paveAmericaClientForm: FormGroup;

	countries = COUNTRIES;
	states = STATES;
	statusToLabel = paveAmericaClientRequestStatusToLabel;

	get paveAmericaClientCountry(): string {
		return this.paveAmericaClientForm.value.address.country;
	}

	get paveAmericaClientState(): string {
		return this.paveAmericaClientForm.value.address.state;
	}

	get paveAmericaClientGeolocation() {
		return this.paveAmericaClientForm.value.address.geolocation;
	}

	get canEdit(): boolean {
		return this.data?.mode === 'view' ? this.data.canUpdate : true;
	}

	get canEditNotes(): boolean {
		return this.data?.mode === 'create' || (this.data?.mode === 'view' && !this.data.isAdmin);
	}

	get canResend(): boolean {
		return (
			this.data?.mode === 'view' &&
			!this.data.isAdmin &&
			DECLINED_PAVE_AMERICA_CLIENT_REQUEST_STATUSES.includes(this.data.paveAmericaClientRequest.status)
		);
	}

	constructor(
		private cd: ChangeDetectorRef,
		private modalService: ModalService,
		public dialogRef: PopupDlgOverlayRef,
		@Inject(POPUP_DLG_DATA) public data: PaveAmericaClientRequestDlgComponentData
	) {
		this.setDefaults();
	}

	setDefaults() {
		this.isUpdateRequest = false;
		this.showPaveAmericaClientColumn = false;

		this.form = new FormGroup({
			name: new FormControl<string>(null, Validators.required),
			email: new FormControl<string>(null),
			address: getAddressFormGroup(false),
			phones: new FormArray<FormControl<Phone>>([], arrayExactLength(1)),
			notes: new FormControl<string>(null, Validators.maxLength(500)),
		});

		this.paveAmericaClientForm = new FormGroup({
			name: new FormControl<string>({ value: '', disabled: true }),
			email: new FormControl<string>({ value: '', disabled: true }),
			address: getAddressFormGroup(false, true),
			phone: new FormGroup({
				type: new FormControl<string>({ value: '', disabled: true }),
				number: new FormControl<string>({ value: '', disabled: true }),
			}),
		});
	}

	ngOnInit() {
		this.init();
	}

	init(notes?: string) {
		switch (this.data.mode) {
			case 'create': {
				this.isUpdateRequest = Boolean(this.data.paveAmericaClient.id);
				this.form.patchValue({ ...this.data.paveAmericaClient, notes: notes ?? null });
				break;
			}
			case 'view': {
				this.isUpdateRequest = Boolean(this.data.paveAmericaClientRequest.paveAmericaClientId);
				this.form.patchValue({
					name: this.data.paveAmericaClientRequest.name,
					email: this.data.paveAmericaClientRequest.email,
					address: this.data.paveAmericaClientRequest.address,
					notes: this.data.paveAmericaClientRequest.notes,
				});
				if (this.isUpdateRequest) {
					this.paveAmericaClientForm.patchValue(this.data.paveAmericaClientRequest.paveAmericaClient);
				}
				if (!this.canEdit) {
					this.form.disable();
				}
				if (!this.canEditNotes) {
					this.form.removeControl('notes');
				}
				break;
			}
		}
		this.cd.markForCheck();
	}

	ngAfterViewInit(): void {
		switch (this.data.mode) {
			case 'create': {
				this.phones.dataEmitter.emit([this.data.paveAmericaClient.phone ?? { type: 'Phone', number: '' }]);
				break;
			}
			case 'view': {
				this.phones.dataEmitter.emit([this.data.paveAmericaClientRequest.phone]);
				break;
			}
		}
		this.cd.markForCheck();
	}

	getStateLabel() {
		return this.states[this.paveAmericaClientCountry].find((state) => state.abbreviation === this.paveAmericaClientState)?.name ?? '';
	}

	save() {
		switch (this.data.mode) {
			case 'create': {
				this.dialogRef.close({
					type: 'create',
					payload: this.getSavePayload(),
					clientId: this.data.clientId,
					paveAmericaClientId: this.data.paveAmericaClient.id,
				} satisfies PaveAmericaClientRequestDlgComponentOutput);
				return;
			}
			case 'view': {
				this.dialogRef.close({
					type: 'update',
					payload: this.getSavePayload(),
				} satisfies PaveAmericaClientRequestDlgComponentOutput);
				return;
			}
		}
	}

	approve() {
		this.dialogRef.close({ type: 'approve', payload: this.getSavePayload() } satisfies PaveAmericaClientRequestDlgComponentOutput);
	}

	decline() {
		this.modalService
			.open(PaveAmericaClientRequestReasonOfRejection, null, false)
			.onResult()
			.pipe(
				filter(Boolean),
				tap((reasonOfRejection) => {
					this.dialogRef.close({ type: 'decline', reasonOfRejection } satisfies PaveAmericaClientRequestDlgComponentOutput);
				})
			)
			.subscribe();
	}

	cancel() {
		this.dialogRef.close(null);
	}

	getSavePayload(): PaveAmericaClientRequestDlgComponentSavePayload {
		return {
			name: this.form.value.name,
			email: this.form.value.email,
			address: this.form.value.address,
			phone: this.form.value.phones[0] ?? null,
			...(this.form.controls.notes && {
				notes: this.form.value.notes ?? null,
			}),
		};
	}

	resend() {
		if (this.data.mode === 'view') {
			const request = this.data.paveAmericaClientRequest;

			this.data = {
				mode: 'create',
				paveAmericaClient: {
					...(request.paveAmericaClientId && { id: request.paveAmericaClientId }),
					name: request.name,
					email: request.email,
					address: request.address,
					phone: request.phone,
				},
				clientId: request.clientId,
			};

			this.setDefaults();
			this.init(request.notes);
			this.ngAfterViewInit();
		}
	}
}
