import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { COSTS_BLOCKS } from '@pss2-shared/costsBlocks';
import { DashboardSettings } from 'src/app/dashboard/components/dashboard/dashboard.component';

type WorkflowGridName =
	| 'leads'
	| 'lead-opportunities'
	| 'opportunities'
	| 'opportunity-bids'
	| 'bids'
	| 'bid-notes'
	| 'bid-history'
	| 'jobs'
	| 'job-notes'
	| 'job-site-reports'
	| 'job-history'
	| 'schedule-jobs';

type CRMGridName =
	| 'clients'
	| 'client-bids'
	| 'client-properties'
	| 'client-managers'
	| 'properties'
	| 'property-bids'
	| 'managers'
	| 'manager-bids'
	| 'mass-email-history';

type ProductionGridName =
	| 'labor-crews'
	| 'labor-employees'
	| 'labor-employee-training-courses'
	| 'labor-employee-training-courses-history'
	| 'track-time-employees'
	| 'track-time-trucks';

type SettingsGridName =
	| 'categories'
	| 'proposal-items'
	| 'proposal-sets'
	| 'labor-divisions'
	| 'business-divisions'
	| 'business-division-history'
	| 'concrete-standards'
	| 'material-fees'
	| 'opportunity-sources'
	| 'opportunity-types'
	| 'opportunity-statuses'
	| 'note-categories'
	| 'job-statuses'
	| 'groups'
	| 'employee-work-classes'
	| 'site-report-forms'
	| 'direct-costs-sets';

type ReportGridName =
	| 'payroll'
	| 'job-pricing-statistics'
	| 'job-costs'
	| 'daily-jobs'
	| 'daily-jobs-wo'
	| 'daily-crews'
	| 'profitability'
	| 'bid-lost-survey'
	| 'job-survey'
	| 'material-report';

type AdminGridName =
	| 'nps-statistic'
	| 'admin-companies'
	| 'admin-plants'
	| `${COSTS_BLOCKS}-default-direct-cost`
	| 'pave-america-clients'
	| 'pave-america-local-clients'
	| 'admin-pave-america-client-requests'
	| 'pave-america-project-departments'
	| 'pave-america-gl-codes'
	| `${COSTS_BLOCKS}-regional-direct-cost`
	| 'regional-direct-cost-history'
	| 'pave-america-net-suite-import-logs'
	| 'pave-america-net-suite-imports'
	| 'pave-america-net-suite-import-records'

type InterCompanyGridName = 'intercompany-requests-sent' | 'intercompany-requests-received' | 'intercompany-opportunity-requests' | 'intercompany-bid-requests';

type DirectCostGridName = `${COSTS_BLOCKS}-direct-cost` | 'direct-cost-history';

export type GridName =
	| WorkflowGridName
	| CRMGridName
	| ProductionGridName
	| SettingsGridName
	| ReportGridName
	| AdminGridName
	| InterCompanyGridName
	| DirectCostGridName
	| 'notes'
	| 'company-plants'
	| 'site-reports'
	| 'site-report-history'
	| 'asphalt-index'
	| 'asphalt-index-history'
	| 'company-files'
	| 'office-site-report-settings'
	| 'pave-america-client-requests'
	| 'employee-working-hours'
	| 'completed-training-courses'
	| 'qb-desktop'
	| 'admin-site-reports'
	| 'admin-site-report-history';

export type GridSettings = Record<string, { hidden: boolean }> & {
	_columnOrder?: string[];
	_pageSize: number;
};
export type GridsSettings = {
	[key in GridName]: GridSettings;
};

@Injectable({
	providedIn: 'root',
})
export class PersistenceService {
	navigationMenuChange$ = new Subject<void>();
	defaultPageSize: number = 25;

	set schedulerIsFullScreen(v: boolean) {
		this.set('schedulerIsFullScreen', v);
	}

	get schedulerIsFullScreen() {
		return Boolean(this.get('schedulerIsFullScreen'));
	}

	set schedulerView(v: string) {
		this.set('schedulerView', v);
	}

	get schedulerView(): string {
		return this.get('schedulerView');
	}

	set schedulerIsTooltipEnabled(v: boolean) {
		this.set('schedulerIsTooltipEnabled', v);
	}

	get schedulerIsTooltipEnabled(): boolean {
		return Boolean(this.get('schedulerIsTooltipEnabled'));
	}

	set dashboardSettings(v: DashboardSettings) {
		this.set('dashboardSettings', v);
	}

	get dashboardSettings(): DashboardSettings {
		return this.get('dashboardSettings');
	}

	get linksInNewTabs(): boolean {
		return this.get('linksInNewTabs') ?? true;
	}

	set linksInNewTabs(v: boolean) {
		this.set('linksInNewTabs', v);
	}

	get isActivitiesPaneExpanded(): boolean {
		return this.get('isActivitiesPaneExpanded') ?? false;
	}

	set isActivitiesPaneExpanded(value: boolean) {
		this.set('isActivitiesPaneExpanded', value);
	}

	constructor() {}

	set(key: string, data: any): void {
		try {
			localStorage.setItem(key, JSON.stringify(data));
		} catch (e) {
			console.error('Error saving to localStorage', e);
		}
	}

	get(key: string) {
		try {
			return JSON.parse(localStorage.getItem(key));
		} catch (e) {
			console.error('Error getting data from localStorage', e);
			return null;
		}
	}

	getGridSettings(gridName: string): GridSettings {
		const gridsSettings = this.get('gridsSettings');
		return (
			gridsSettings?.[gridName] || {
				_pageSize: this.defaultPageSize,
			}
		);
	}

	setGridSettings(gridName: string, settings: GridSettings): void {
		let gridsSettings = this.get('gridsSettings');
		if (!gridsSettings) {
			gridsSettings = {};
		}
		gridsSettings[gridName] = settings;
		this.set('gridsSettings', gridsSettings);
	}

	setLoginBackground(loginBackground: string | null): void {
		if (!loginBackground) {
			localStorage.removeItem('loginBackground');
			return;
		}

		this.set('loginBackground', loginBackground);
	}

	getLoginBackground(): string | null {
		return this.get('loginBackground');
	}
}
