import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralTableParams } from '../../../../../pss2-shared/commonTypes';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';

@Injectable({
	providedIn: 'root',
})
export class BusinessDivisionsHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'entities/business-divisions');
	}

	getPreviousYearRevenue(id: number, year: number) {
		return this.http.get<{ days: any[] }>(`${this.entityUrl}/${id}/last-year-daily-revenue/${year}`);
	}

	saveGoals(
		id: number,
		year: number,
		body: {
			revenueGoals: number[];
			profitGoals: number[];
			profitPercentGoals: number[];
		}
	) {
		return this.http.post(`${this.entityUrl}/${id}/goals/${year}`, body);
	}

	getHistory(businessDivisionId: number, params: GeneralTableParams) {
		return this.http.get<any>(`${this.entityUrl}/${businessDivisionId}/history`, {
			params,
		});
	}
}
