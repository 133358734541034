<clr-datagrid class="grid" (clrDgRefresh)="reloadItems($event)" [clrDgLoading]="loading">
	<clr-dg-column clrDgField="name" [style.order]="gridSettingsService.getColumnOrder('name')">
		<ng-template
			clrDgHideableColumn
			[clrDgHidden]="gridSettingsService.getIsColumnHidden('name')"
			(clrDgHiddenChange)="gridSettingsService.persistColumnVisibility('name', $event)">
			Name
		</ng-template>
		<clr-dg-filter [clrDgFilter]="nameFilter" appClarityDgFilter>
			<app-clr-dg-string-filter #nameFilter property="name"></app-clr-dg-string-filter>
		</clr-dg-filter>
	</clr-dg-column>
	<clr-dg-column clrDgField="requestedAt" [clrDgSortOrder]="-1" [style.order]="gridSettingsService.getColumnOrder('requesterAt')">
		<ng-template
			clrDgHideableColumn
			[clrDgHidden]="gridSettingsService.getIsColumnHidden('requestedAt')"
			(clrDgHiddenChange)="gridSettingsService.persistColumnVisibility('requestedAt', $event)">
			Requested At
		</ng-template>
		<clr-dg-filter [clrDgFilter]="requestedAtFilter" appClarityDgFilter>
			<app-clr-dg-date-filter #requestedAtFilter property="requestedAt"></app-clr-dg-date-filter>
		</clr-dg-filter>
	</clr-dg-column>
	<clr-dg-column [style.order]="gridSettingsService.getColumnOrder('requester')">
		<ng-template
			clrDgHideableColumn
			[clrDgHidden]="gridSettingsService.getIsColumnHidden('requester')"
			(clrDgHiddenChange)="gridSettingsService.persistColumnVisibility('requester', $event)">
			Requester
		</ng-template>
		<clr-dg-filter [clrDgFilter]="requesterFilter" appClarityDgFilter>
			<app-clr-dg-multiselect-filter #requesterFilter property="requesterId" [options]="users"></app-clr-dg-multiselect-filter>
		</clr-dg-filter>
	</clr-dg-column>
	<clr-dg-column [style.order]="gridSettingsService.getColumnOrder('inboundReviewer')">
		<ng-template
			clrDgHideableColumn
			[clrDgHidden]="gridSettingsService.getIsColumnHidden('inboundReviewer')"
			(clrDgHiddenChange)="gridSettingsService.persistColumnVisibility('inboundReviewer', $event)">
			Inbound Reviewer
		</ng-template>
		<clr-dg-filter [clrDgFilter]="inboundReviewerFilter" appClarityDgFilter>
			<app-clr-dg-multiselect-filter #inboundReviewerFilter property="inboundReviewerId" [options]="inboundReviewers"></app-clr-dg-multiselect-filter>
		</clr-dg-filter>
	</clr-dg-column>
	<clr-dg-column [style.order]="gridSettingsService.getColumnOrder('status')">
		<ng-template
			clrDgHideableColumn
			[clrDgHidden]="gridSettingsService.getIsColumnHidden('status')"
			(clrDgHiddenChange)="gridSettingsService.persistColumnVisibility('status', $event)">
			Status
		</ng-template>
		<clr-dg-filter [clrDgFilter]="statusFilter" appClarityDgFilter>
			<app-clr-dg-multiselect-filter #statusFilter property="status" [options]="statusOptions" [selected]="selectedStatuses">
			</app-clr-dg-multiselect-filter>
		</clr-dg-filter>
	</clr-dg-column>
	<clr-dg-column style="width: 90px;" [style.order]="gridSettingsService.getColumnOrder('view')">
		<ng-template
			clrDgHideableColumn
			[clrDgHidden]="gridSettingsService.getIsColumnHidden('view')"
			(clrDgHiddenChange)="gridSettingsService.persistColumnVisibility('view', $event)">
			View
		</ng-template>
	</clr-dg-column>

	<clr-dg-row *ngFor="let request of response.results">
		<clr-dg-cell [style.order]="gridSettingsService.getColumnOrder('name')">
			<a [target]="gridSettingsService.linkTarget" [routerLink]="['/crm/clients/edit', request.clientId, 'details']">
				{{request.name}}
			</a>
		</clr-dg-cell>
		<clr-dg-cell [style.order]="gridSettingsService.getColumnOrder('requesterAt')">{{request.requestedAt | timezone | date}}</clr-dg-cell>
		<clr-dg-cell [style.order]="gridSettingsService.getColumnOrder('requester')">{{usersIndex[request.requesterId]?.name ?? 'Unknown User'}}</clr-dg-cell>
		<clr-dg-cell [style.order]="gridSettingsService.getColumnOrder('inboundReviewer')">
			{{request.inboundReviewerId ? usersIndex[request.inboundReviewerId]?.name ?? 'Unknown User' : 'Not Reviewed'}}
		</clr-dg-cell>
		<clr-dg-cell [style.order]="gridSettingsService.getColumnOrder('status')">{{statusToLabel[request.status]}}</clr-dg-cell>
		<clr-dg-cell class="view-button-cell" [style.order]="gridSettingsService.getColumnOrder('view')">
			<button class="btn btn-sm btn-primary" (click)="viewRequest(request.id)" [clrLoading]="viewLoadingState">View</button>
		</clr-dg-cell>
		<ng-container ngProjectAs="clr-dg-row-detail" *ngIf="request.notes || request.reasonOfRejection">
			<clr-dg-row-detail *clrIfExpanded>
				<div>
					<div *ngIf="request.notes" class="clr-row clr-align-items-center">
						<div class="clr-col-auto clr-control-label">Notes:</div>
						<div class="clr-col">{{request.notes}}</div>
					</div>
					<div *ngIf="request.reasonOfRejection" class="clr-row clr-align-items-center">
						<div class="clr-col-auto clr-control-label">Reason of rejection:</div>
						<div class="clr-col">{{request.reasonOfRejection}}</div>
					</div>
				</div>
			</clr-dg-row-detail>
		</ng-container>
	</clr-dg-row>
	<clr-dg-footer>
		<app-clr-dg-footer-content> </app-clr-dg-footer-content>
		<clr-dg-pagination #pagination [clrDgPageSize]="gridSettingsService.pageSize" [clrDgTotalItems]="response.total">
			<clr-dg-page-size [clrPageSizeOptions]="[25,50,100,500]">Items per page</clr-dg-page-size>
			{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{response.total}} items
		</clr-dg-pagination>
	</clr-dg-footer>
</clr-datagrid>
