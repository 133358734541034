<div cdkDrag class="modal-dialog" role="dialog" aria-hidden="true">
	<div class="modal-content modal-lg">
		<div class="modal-dlg-header">
			<clr-icon class="drag-handle-icon btn btn-link" shape="drag-handle"></clr-icon>
			<h3 class="modal-title">{{isUpdateRequest ? 'Update Pave America Client Information' : 'Add Pave America Client'}}</h3>
			<button aria-label="Close" class="close-modal-btn btn btn-link" type="button" (click)="cancel()">
				<clr-icon aria-hidden="true" shape="close"></clr-icon>
			</button>
		</div>
		<div class="modal-body">
			<div class="clr-row">
				<div class="clr-col request-client">
					<h3 class="remove-margin">
						Request
						<button
							*ngIf="data.mode === 'view' && isUpdateRequest"
							class="btn btn-outline btn-sm remove-margin"
							type="button"
							(click)="showPaveAmericaClientColumn=!showPaveAmericaClientColumn">
							{{showPaveAmericaClientColumn ? 'Hide PA Client' : 'Show PA Client'}}
						</button>
					</h3>
					<form clrForm [formGroup]="form">
						<clr-input-container>
							<label>Name</label>
							<input clrInput type="text" formControlName="name" />
							<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
						</clr-input-container>
						<clr-input-container>
							<label>Email</label>
							<input clrInput type="email" formControlName="email" />
						</clr-input-container>
						<section class="title">
							<h4>Address</h4>
						</section>
						<app-address formGroupName="address"></app-address>
					</form>
				</div>
				<div class="clr-col-5 pave-america-client" *ngIf="showPaveAmericaClientColumn">
					<h3 class="remove-margin">Pave America Client</h3>
					<form clrForm [formGroup]="paveAmericaClientForm">
						<div class="clr-form-control">
							<div class="clr-input-wrapper">
								<input class="clr-input" type="text" formControlName="name" />
							</div>
						</div>
						<div class="clr-form-control">
							<div class="clr-input-wrapper">
								<input class="clr-input" type="text" formControlName="email" />
							</div>
						</div>
						<div class="pave-america-client-address" [formGroupName]="'address'">
							<div class="clr-form-control">
								<div class="clr-input-wrapper">
									<input class="clr-input" type="text" formControlName="street" />
								</div>
							</div>
							<div class="clr-form-control">
								<div class="clr-input-wrapper">
									<input class="clr-input" type="text" formControlName="suite" />
								</div>
							</div>
							<div class="clr-form-control">
								<div class="clr-input-wrapper">
									<input class="clr-input" type="text" formControlName="city" />
								</div>
							</div>
							<div class="clr-form-control">
								<div class="clr-select-wrapper">
									<select class="clr-select" formControlName="country">
										<option [value]="paveAmericaClientCountry">
											{{countries[paveAmericaClientCountry] ?? ''}}
										</option>
									</select>
								</div>
							</div>
							<div class="clr-form-control">
								<div class="clr-select-wrapper">
									<select class="clr-select" formControlName="state">
										<option [value]="paveAmericaClientState">
											{{getStateLabel()}}
										</option>
									</select>
								</div>
							</div>
							<div class="clr-form-control">
								<div class="clr-input-wrapper">
									<input class="clr-input" type="text" formControlName="postalCode" />
								</div>
							</div>
							<div *ngIf="paveAmericaClientGeolocation?.lat || paveAmericaClientGeolocation?.lng" class="clr-form-control">
								<label class="clr-control-label pave-america-client-coords-label">Lat: {{paveAmericaClientGeolocation.lat}}</label>
								<label class="clr-control-label pave-america-client-coords-label">Lng: {{paveAmericaClientGeolocation.lng}}</label>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="clr-row">
				<div class="clr-col request-client">
					<form clrForm [formGroup]="form">
						<app-phones #phones formArrayName="phones" [withoutControls]="true" [isReadonly]="!canEdit"></app-phones>
					</form>
				</div>
				<div class="clr-col-5 pave-america-client" *ngIf="showPaveAmericaClientColumn">
					<form clrForm [formGroup]="paveAmericaClientForm">
						<div class="pave-america-client-phone" [formGroupName]="'phone'">
							<div class="clr-form-control">
								<div class="clr-input-wrapper">
									<input class="clr-input" type="text" formControlName="type" />
								</div>
							</div>
							<div class="clr-form-control">
								<div class="clr-input-wrapper">
									<input class="clr-input" type="text" formControlName="number" />
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div *ngIf="canEditNotes" class="clr-row">
				<div class="clr-col request-client">
					<form clrForm [formGroup]="form">
						<clr-textarea-container>
							<label>Request Notes</label>
							<textarea clrTextarea formControlName="notes" placeholder="Enter notes for a reviewer" [rows]="5"></textarea>
							<clr-control-error *clrIfError="'maxlength'">It must be at most 500 characters!</clr-control-error>
						</clr-textarea-container>
					</form>
				</div>
			</div>
			<form *ngIf="data.mode === 'view'" clrForm>
				<div class="info-line" *ngIf="!canEditNotes && data.paveAmericaClientRequest.notes">
					<span>Notes:</span>
					<span class="plain">{{data.paveAmericaClientRequest.notes}}</span>
				</div>
				<div class="info-line">
					<span>Status:</span>
					<span>{{statusToLabel[data.paveAmericaClientRequest.status]}}</span>
				</div>
				<div class="info-line" *ngIf="data.paveAmericaClientRequest.reasonOfRejection">
					<span>Reason of rejection:</span>
					<span>{{data.paveAmericaClientRequest.reasonOfRejection}}</span>
				</div>
				<div class="info-line">
					<span>Requested By:</span>
					<span>{{data.paveAmericaClientRequest.requester.name}}</span>
				</div>
				<div class="info-line">
					<span>Requested At:</span>
					<span>{{data.paveAmericaClientRequest.requestedAt | timezone | date}}</span>
				</div>
				<ng-container *ngIf="data.paveAmericaClientRequest.inboundReviewerId">
					<div class="info-line">
						<span>Inbound Reviewed By:</span>
						<span>{{data.paveAmericaClientRequest.inboundReviewer.name}}</span>
					</div>
					<div class="info-line">
						<span>Inbound Reviewed At:</span>
						<span>{{data.paveAmericaClientRequest.inboundReviewedAt | timezone | date}}</span>
					</div>
				</ng-container>
				<ng-container *ngIf="data.paveAmericaClientRequest.outboundReviewerId">
					<div class="info-line">
						<span>Outbound Reviewed By:</span>
						<span>{{data.paveAmericaClientRequest.outboundReviewer.name}}</span>
					</div>
					<div class="info-line">
						<span>Outbound Reviewed At:</span>
						<span>{{data.paveAmericaClientRequest.outboundReviewedAt | timezone | date}}</span>
					</div>
				</ng-container>
			</form>
		</div>
		<div class="modal-footer" [ngSwitch]="data.mode">
			<ng-container *ngSwitchCase="'create'">
				<button class="btn btn-primary" type="button" [disabled]="form.invalid" (click)="save()">Create</button>
				<button class="btn" type="button" (click)="cancel()">Cancel</button>
			</ng-container>
			<ng-container *ngSwitchCase="'view'">
				<button *ngIf="canResend" class="btn btn-primary" type="button" (click)="resend()">Resend</button>
				<div class="btn-group">
					<button *ngIf="data.mode === 'view' && data.canApprove" class="btn btn-primary" type="button" [disabled]="form.invalid" (click)="approve()">
						Approve
					</button>
					<button *ngIf="data.mode === 'view' && data.canUpdate" class="btn btn-danger" type="button" (click)="decline()">Decline</button>
				</div>
				<button *ngIf="data.mode === 'view' && data.canUpdate" class="btn btn-primary" type="button" [disabled]="form.invalid" (click)="save()">
					Save
				</button>
				<button class="btn" type="button" (click)="cancel()">Cancel</button>
			</ng-container>
		</div>
	</div>
</div>
