import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	DashboardBidStats,
	DashboardForecastStats,
	DashboardGoalProgressStats,
	DashboardJobStats,
	DashboardRevenueStats,
	DashboardSalespeopleBidClosingRatioStatistic,
	DashboardSalespeopleCycleTimeStatistic,
	DashboardSalespeopleDailyJobStatistic,
	DashboardSalespeopleJobCategoriesStatistic,
	DashboardSalespeopleMoreStatsCrewsScheduled,
	DashboardSalespeopleMoreStatsIncompleteBids,
	GetDashboardSalespeopleMoreStatsJobsQuerystring,
	DashboardSalespeopleMoreStatsJobs,
	DashboardSalespeopleMoreStatsJobsScheduled,
	DashboardSalespeopleMoreStatsJobsUnscheduled,
	DashboardSalespeopleMoreStatsOpportunities,
	DashboardSalespeopleMoreStatsPendingBids,
	DashboardSalespeopleOpportunitySourcesStatistic,
	DashboardSalespeopleStatistic,
	DashboardScheduledWorkStats,
	SalespeopleTeams,
	DashboardSalesStats,
	DashboardEmployeeStats,
	GetDashboardSalespeopleMoreStatsQuerystring,
	DashboardExecutiveDailyStats,
} from '../../../../../pss2-shared/apiModels/index';

@Injectable({
	providedIn: 'root',
})
export class DashboardsHttpService {
	private root = '/api/restricted/workflow/dashboards';

	constructor(private http: HttpClient) {}

	getSalespeopleStats(startDate: string, endDate: string) {
		return this.http.get<DashboardSalespeopleStatistic>(`${this.root}/salespeople-stats`, { params: { startDate, endDate } });
	}

	getSalespeopleTeams() {
		return this.http.get<SalespeopleTeams>(`${this.root}/salespeople-stats/teams`);
	}

	getSalespeopleDailyJobStats(startDate: string, endDate: string) {
		return this.http.get<DashboardSalespeopleDailyJobStatistic>(`${this.root}/salespeople-stats/daily-job`, { params: { startDate, endDate } });
	}

	getSalespeopleJobCategoriesStats(startDate: string, endDate: string) {
		return this.http.get<DashboardSalespeopleJobCategoriesStatistic>(`${this.root}/salespeople-stats/job-categories`, { params: { startDate, endDate } });
	}

	getSalespeopleOpportunitySourcesStats(startDate: string, endDate: string) {
		return this.http.get<DashboardSalespeopleOpportunitySourcesStatistic>(`${this.root}/salespeople-stats/opportunity-sources`, {
			params: { startDate, endDate },
		});
	}

	getSalespeopleBidClosingRatioStats(startDate: string, endDate: string) {
		return this.http.get<DashboardSalespeopleBidClosingRatioStatistic>(`${this.root}/salespeople-stats/bid-closing-ratio`, {
			params: { startDate, endDate },
		});
	}

	getSalespeopleBidCycleTimeStats(startDate: string, endDate: string) {
		return this.http.get<DashboardSalespeopleCycleTimeStatistic>(`${this.root}/salespeople-stats/cycle-time`, { params: { startDate, endDate } });
	}

	getSalespeopleStatsMoreStatsOpportunities(params: GetDashboardSalespeopleMoreStatsQuerystring) {
		return this.http.get<DashboardSalespeopleMoreStatsOpportunities>(`${this.root}/salespeople-stats/more-stats/opportunities`, { params: { ...params } });
	}

	getSalespeopleStatsMoreStatsIncompleteBids(params: GetDashboardSalespeopleMoreStatsQuerystring) {
		return this.http.get<DashboardSalespeopleMoreStatsIncompleteBids>(`${this.root}/salespeople-stats/more-stats/incomplete-bids`, {
			params: { ...params },
		});
	}

	getSalespeopleStatsMoreStatsPendingBids(params: GetDashboardSalespeopleMoreStatsQuerystring) {
		return this.http.get<DashboardSalespeopleMoreStatsPendingBids>(`${this.root}/salespeople-stats/more-stats/pending-bids`, { params: { ...params } });
	}

	getSalespeopleStatsMoreStatsJobs(params: GetDashboardSalespeopleMoreStatsJobsQuerystring = {}) {
		return this.http.get<DashboardSalespeopleMoreStatsJobs>(`${this.root}/salespeople-stats/more-stats/jobs`, { params: { ...params } });
	}

	getSalespeopleStatsMoreStatsJobsScheduled(params: GetDashboardSalespeopleMoreStatsQuerystring) {
		return this.http.get<DashboardSalespeopleMoreStatsJobsScheduled>(`${this.root}/salespeople-stats/more-stats/jobs-scheduled`, { params: { ...params } });
	}

	getSalespeopleStatsMoreStatsJobsUnscheduled(params: GetDashboardSalespeopleMoreStatsQuerystring) {
		return this.http.get<DashboardSalespeopleMoreStatsJobsUnscheduled>(`${this.root}/salespeople-stats/more-stats/jobs-unscheduled`, {
			params: { ...params },
		});
	}

	getSalespeopleStatsMoreStatsCrewsScheduled(params: GetDashboardSalespeopleMoreStatsQuerystring) {
		return this.http.get<DashboardSalespeopleMoreStatsCrewsScheduled>(`${this.root}/salespeople-stats/more-stats/crews-scheduled`, {
			params: { ...params },
		});
	}

	getRevenueStats(startDate: string, endDate: string) {
		return this.http.get<DashboardRevenueStats>(`${this.root}/revenue-stats`, { params: { startDate, endDate } });
	}

	getGoalProgressStats(startDate: string, endDate: string) {
		return this.http.get<DashboardGoalProgressStats>(`${this.root}/goal-progress-stats`, { params: { startDate, endDate } });
	}

	getScheduledWorkStats(startDate: string, endDate: string) {
		return this.http.get<DashboardScheduledWorkStats>(`${this.root}/scheduled-work-stats`, { params: { startDate, endDate } });
	}

	getForecastStats(startDate: string, endDate: string) {
		return this.http.get<DashboardForecastStats>(`${this.root}/forecast-stats`, { params: { startDate, endDate } });
	}

	getBidStats(startDate: string, endDate: string) {
		return this.http.get<DashboardBidStats>(`${this.root}/bid-stats`, { params: { startDate, endDate } });
	}

	getJobStats(startDate: string, endDate: string) {
		return this.http.get<DashboardJobStats>(`${this.root}/job-stats`, { params: { startDate, endDate } });
	}

	getSalesStats(startDate: string, endDate: string) {
		return this.http.get<DashboardSalesStats>(`${this.root}/sales-stats`, { params: { startDate, endDate } });
	}

	getEmployeeStats(startDate: string, endDate: string) {
		return this.http.get<DashboardEmployeeStats>(`${this.root}/employee-stats`, { params: { startDate, endDate } });
	}

	getExecutiveDailyStats() {
		return this.http.get<DashboardExecutiveDailyStats>(`${this.root}/executive-daily-stats`);
	}
}
