import { Component, ChangeDetectionStrategy, Optional, Self, Input, OnInit } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NgControl } from '@angular/forms';

export const fontsSizes = ['5pt', '6pt', '7pt', '8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];

@Component({
	selector: 'app-proposal-text-editor',
	templateUrl: './proposal-text-editor.component.html',
	styleUrls: ['./proposal-text-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	// encapsulation: ViewEncapsulation.ShadowDom
})
export class ProposalTextEditorComponent implements ControlValueAccessor, OnInit {
	@Input()
	placeholders = [];

	@Input()
	hideToolBar = false;

	@Input()
	placeholder = 'Insert text here...';

	@Input()
	styles = null;

	modules;

	sizes = fontsSizes;

	control = new UntypedFormControl('');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onChange = (value: any) => {};
	onTouched = () => {};

	constructor(@Optional() @Self() public controlDir: NgControl) {
		// bind the CVA to our control
		controlDir.valueAccessor = this;
		this.control.valueChanges.subscribe((value) => {
			if (!this.control.dirty) return;
			this.onChange(value);
		});
	}

	ngOnInit(): void {
		this.modules = {
			toolbar: false,
			imageCompress: {
				quality: 0.5,
				maxWidth: 640,
				imageType: 'image/jpeg',
				debug: false,
			},
			blotFormatter: {},
			placeholder: this.placeholders
				? {
						placeholders: this.placeholders,
					}
				: null,
		};
	}

	writeValue(obj: any): void {
		this.control.setValue(obj);
		// this.onChange(obj);
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) this.control.disable();
		else this.control.enable();
	}
}
